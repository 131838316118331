import React, {useState, useEffect, useRef} from 'react';

// Prime Component
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';

/*Component*/
import ContentLoading from '../loading/ContentLoading';

/*Service*/
import {Mt4UsersGetList} from '../../service/mt4Service';


const UsersList = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [mt4UsersList, setMt4UsersList] = useState([]);

    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedPage, setSelectedPage] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            'name': { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            'company': { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' },
        }
    });


    let loadLazyTimeout = null;

    useEffect(() => {
        loadLazyData();
    },[lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        //imitate delay of a backend call
        loadLazyTimeout = setTimeout(() => {
            Mt4UsersGetList(selectedPage,lazyParams.rows).then(data => {
                console.log("data", data)
                setTotalRecords(data.totalElements);
                setMt4UsersList(data);
                setLoading(false);
                setIsLoading(false)
            });
        }, Math.random() * 1000 + 250);
    }


    const onPage = (event) => {
        setLazyParams(event);
        lazyParams.rows = event.rows
        setSelectedPage(event.page + 1);
    }

    const onSort = (event) => {
        setLazyParams(event);
    }
    


    return (

        <>
        <Toast ref={toast}/>
            {isLoading ? (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <ContentLoading/>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="grid p-fluid">
                        <div className="col-12">
                            <div className="card">
                                <h5>MT4 Kullanıcı Listesi</h5>
                                <p>MT4 Kullanıcı listesinde yer alanlar aşağıda listelenmektedir.</p>

                                <DataTable value={mt4UsersList.content} lazy responsiveLayout="scroll" dataKey="id"
                                    paginator 
                                    first={lazyParams.first} 
                                    rows={lazyParams.rows}
                                    rowsPerPageOptions={[10,20,30,40,50,100,150,200,250,300,350,400,450,500,1000]}
                                    totalRecords={totalRecords} 
                                    onPage={onPage}
                                    onSort={onSort} 
                                    sortField={lazyParams.sortField} 
                                    sortOrder={lazyParams.sortOrder}
                                    loading={loading}
                                    >
                                    <Column field="zipcode" header="zipcode"></Column>
                                    <Column field="userColor" header="userColor"></Column>
                                    <Column field="timestamp" header="timestamp"></Column>
                                    <Column field="taxes" header="taxes"></Column>
                                    <Column field="status" header="status"></Column>
                                    <Column field="state" header="state"></Column>
                                    <Column field="sendReports" header="sendReports"></Column>
                                    <Column field="regDate" header="regDate"></Column>
                                    <Column field="prevMonthBalance" header="prevMonthBalance"></Column>
                                    <Column field="prevBalance" header="prevBalance"></Column>
                                    <Column field="phone" header="phone"></Column>
                                    <Column field="passwordPhone" header="passwordPhone"></Column>
                                    <Column field="name" header="name"></Column>
                                    <Column field="mqId" header="mqId"></Column>
                                    <Column field="modifyTime" header="modifyTime"></Column>
                                    <Column field="marginLevel" header="marginLevel"></Column>
                                    <Column field="marginFree" header="marginFree"></Column>
                                    <Column field="margin" header="margin"></Column>
                                    <Column field="login" header="login"></Column>
                                    <Column field="leverage" header="leverage"></Column>
                                    <Column field="leadSource" header="leadSource"></Column>
                                    <Column field="lastDate" header="lastDate"></Column>
                                    <Column field="interestRate" header="interestRate"></Column>
                                    <Column field="id" header="id"></Column>
                                    <Column field="group" header="group"></Column>
                                    <Column field="equity" header="equity"></Column>
                                    <Column field="enableReadonly" header="enableReadonly"></Column>
                                    <Column field="enableOtp" header="enableOtp"></Column>
                                    <Column field="enableChangePass" header="enableChangePass"></Column>
                                    <Column field="enable" header="enable"></Column>
                                    <Column field="email" header="email"></Column>
                                    <Column field="currency" header="currency"></Column>
                                    <Column field="creadit" header="creadit"></Column>
                                    <Column field="country" header="country"></Column>
                                    <Column field="comment" header="comment"></Column>
                                    <Column field="city" header="city"></Column>
                                    <Column field="balance" header="balance"></Column>
                                    <Column field="apiDate" header="apiDate"></Column>
                                    <Column field="agentAccount" header="agentAccount"></Column>
                                    <Column field="address" header="address"></Column>
                                </DataTable>
                                <span className='mr-3 mt-3'
                                      style={{display: 'inline-block'}}>Toplam Kayıt Sayısı: {mt4UsersList.totalElements}</span>
                                <span className='mr-3 mt-3'
                                      style={{display: 'inline-block'}}>Toplam Sayfa Sayısı: {mt4UsersList.totalPages}</span>
                            </div>
                        </div>
                </div>
            )}
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UsersList, comparisonFn);

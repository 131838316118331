import {api} from '../constants/string';
import {HTTP} from "../axios/axios";


export function RolePost(data) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/roles`, data)
        .then(response => {
            return res(response.data);
        })
        .catch((error) => {
             
            rej(error);
        }),
    );
}

export function RolePut(data) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/roles`, data)
        .then(response => {
            return res(response.data);
        })
        .catch((error) => {
             
            rej(error);
        }),
);
}

export function RoleGet(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/roles/${id}`)
        .then(response => {
            return res(response.data);
        })
        .catch((error) => {
             
            rej(error);
        }),
    );
}
export function RoleGetList() {
  return new Promise((res, rej) =>
      HTTP.get(`${api}/roles`)
          .then(response => {
              return res(response.data);
          })
          .catch((error) => {
               
              rej(error);
          }),
  );
}
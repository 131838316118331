import {api} from '../constants/string';
import {HTTP} from "../axios/axios";

export function TeamPost(data) {

    return new Promise((res, rej) =>
        HTTP.post(`${api}/groups`, data)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function TeamPut(data) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/groups`, data)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function TeamGet(id) {

    return new Promise((res, rej) =>
        HTTP.get(`${api}/groups/${id}`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function TeamGetList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/groups`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function TeamDetele(id) {
    return new Promise((res, rej) =>
        HTTP.delete(`${api}/groups/${id}`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useRef, useEffect } from "react";

// plugins
import moment from "moment";
import { useTranslation } from "react-i18next";

// Prime Component

/*Component*/
import ContentLoading from "../loading/ContentLoading";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

/*Service*/
import {
  CustomerCreateAll,
  CustomerUnassignmentGetList,
} from "../../service/customer"; //Dökümanı yükle ve json olarak sonucu al
import { PersonnelGetList } from "../../service/personnel";
import { LeadGetList } from "../../service/lead";

import {api, momentDateFormat} from "../../constants/string";
import {HTTP} from "../../axios/axios";

const WebFormList = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [data, setData] = useState(null);
  const [personnelList, setPersonnelList] = useState(null); //Personel Listesini Alır
  const [leadList, setleadListList] = useState(null); //Lead listesini alır
  const [selectedPersonnel, setSelectedPersonnel] = useState(null); //Seçilen Personeli Tutar
  const [selectedLeadState, setSelectedLeadState] = useState(null); //Seçilen Lead Durumunu Tutar
  const [selectedProducts7, setSelectedProducts7] = useState(null); //Checkbox İlle Seçilen Elemenları tutar
  const [loading, setLoading] = useState(false);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);
  const loadLazyData = () => {
    setLoading(true);

    CustomerUnassignmentGetList("UNUSED", lazyParams.page).then((res) => {
      setData(res);
      setTotalRecords(res.totalElements);
      setLoading(false);
      setIsLoading(false);
    });
  };

  const onPage = (event) => {
    setLazyParams(event);
  };

  useEffect(() => {
    PersonnelGetList().then((res) => {
      //Personel Listesini Getirelim
      setPersonnelList(res);
      LeadGetList().then((res) => {
        //Lead Listesini Getirelim
        setleadListList(res);
      });
    });
  }, []);

  //Dropdown Search Code Start
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.name}</div>
      </div>
    );
  };

  const save = () => {
    function DefaultModelCreator(data) {
      return {
        id: data.id,
        phoneNumber: data.phoneNumber,
      };
    }
    setIsLoading(true);

    const idList = selectedProducts7.map(x => x.id);

    selectedProducts7.map((item, i) => {
      item.id = null;
      item.phoneNumberList = [];
      item.leadState = selectedLeadState;
      item.user = selectedPersonnel;
      item.phoneNumberList.push(
        DefaultModelCreator({
          id: "",
          phoneNumber: item.phoneNumber,
        })
      );
    });

    CustomerCreateAll(selectedProducts7, false)
      .then((res) => {
        let insertedList = res;
        console.log("insertedList : ",insertedList)
        HTTP.post(`${api}/customer-unassignment/delete`, insertedList)
        //Seçilenleri kaydetmek için gönderelim
        setIsLoading(false);

        loadLazyData();
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Kayıtlar Eklendi",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };
  const momentCreatedDateBodyTemplate = (rowData) => {
    //Listeleme Tarih Formatı
    return moment(rowData.createdDate).format(momentDateFormat);
  };

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />
          <div className="col-12">
            <div className="card">
              <h5>{t("pasifMusteriListesi.baslik")}</h5>
              <p>
                {t("pasifMusteriListesi.aciklama")}
              </p>
              <div className="col-12">
                <div className="grid">
                  <div className="col-12 md:col-4">
                    <div className="field">
                      <label htmlFor="personnelEMail">
                        {t("googleSheetsList.sorumluPersonel")}
                      </label>
                      <Dropdown
                        value={selectedPersonnel}
                        options={personnelList}
                        onChange={(e) => setSelectedPersonnel(e.value)}
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        placeholder={t("global.placeholderSelect")}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-4">
                    <div className="field">
                      <label htmlFor="personnelEMail">
                        {t("googleSheetsList.leadDurumu")}
                      </label>
                      <Dropdown
                        value={selectedLeadState}
                        options={leadList}
                        onChange={(e) => setSelectedLeadState(e.value)}
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        placeholder={t("global.placeholderSelect")}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-4">
                    <div className="field" style={{ marginTop: 5 }}>
                      <Button
                        label={t("googleSheetsList.aktar")}
                        onClick={save}
                        className="p-button-success mt-4"
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  value={data.content}
                  lazy
                  filterDisplay="row"
                  responsiveLayout="scroll"
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  rows={lazyParams.rows}
                  first={lazyParams.first}
                  totalRecords={totalRecords}
                  onPage={onPage}
                  loading={loading}
                  selection={selectedProducts7}
                  onSelectionChange={(e) => setSelectedProducts7(e.value)}
                  rowsPerPageOptions={[
                    10, 20, 30, 40, 50, 100, 150, 200, 250, 300,
                  ]}
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3em" }}
                  ></Column>
                  <Column
                    field="id"
                    header={t("googleSheetsList.sira")}
                  ></Column>
                  <Column
                    field="name"
                    header={t("googleSheetsList.adi")}
                  ></Column>
                  <Column
                    field="surname"
                    header={t("googleSheetsList.soyadi")}
                  ></Column>
                  <Column
                    field="phoneNumber"
                    header={t("googleSheetsList.telefon")}
                  ></Column>
                  <Column
                    field="referenceCode"
                    header={"Referans Kodu"}
                  ></Column>
                  <Column
                    field="source"
                    header={t("googleSheetsList.kaynak")}
                  ></Column>
                  <Column
                    header={t("googleSheetsList.tarih")}
                    body={momentCreatedDateBodyTemplate}
                  ></Column>
                </DataTable>
                <span className="mr-3 mt-3" style={{ display: "inline-block" }}>
                  Toplam Kayıt Sayısı: {data.totalElements}
                </span>
                <span className="mr-3 mt-3" style={{ display: "inline-block" }}>
                  Toplam Sayfa Sayısı: {data.totalPages}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(WebFormList, comparisonFn);

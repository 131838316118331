/* eslint-disable array-callback-return */
import React, { useState, useRef, useEffect } from "react";

// plugins
import { useTranslation } from "react-i18next";

/*Prime Component*/
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { PersonnelPost } from "../../service/personnel";
import { RoleGetList } from "../../service/role";
import { TeamGetList } from "../../service/team";
import { AuthoritiesGet } from "../../service/authorities";

/*Yetkilendirme ve Rol*/
import { personelEkle, musteriIndirme } from "../../constants/string";

import { JwtHelper } from "../../plugins/jwtHelper";

const Add = () => {
  const { t } = useTranslation();

  const authorityList = JwtHelper.getAuthority(); //Yetkileri getirir
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [data, setData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    password2: "",
    role: null,
    group: null,
    authorityList: null,
  });
  const [roleListData, setRoleListData] = useState([]); //Rol listesi
  const [groupListData, setGroupListData] = useState([]); //Takım listesi
  const [authoritiesListData, setAuthoritiesListData] = useState([]); //Yetki listesi

  useEffect(() => {
    //Rol listesi
    RoleGetList().then((res) => {
      setRoleListData(res);
      TeamGetList().then((res) => {
        setGroupListData(res);
        AuthoritiesGet().then((res) => {
          let authList = [];
          if (JwtHelper.getUserRole() === "admin") {
            authList = res;
          } else {
            res.map((item) => {
              if (item.code !== musteriIndirme) {
                authList.push(item);
              }
            });
          }
          setAuthoritiesListData(authList);
          setIsLoading(false);
        });
      });
    });
  }, []);

  //Dropdown Search Code Start
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.name}</div>
      </div>
    );
  };
  //Dropdown Search Code Start end

  const save = () => {
    if (data.password !== data.password2) {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Şifreler Uyuşmamaktadır.",
        life: 3000,
      });
    } else {
      setIsLoading(true);
      PersonnelPost(data)
        .then((res) => {
          setIsLoading(false);

          toast.current.show({
            severity: "success",
            summary: "Başarılı",
            detail: "Başarılı Şekilde Personel Eklendi",
            life: 3000,
          });
        })
        .catch((err) => {
          setIsLoading(false);

          toast.current.show({
            severity: "error",
            summary: "Hata",
            detail: `${err.response.data}`,
            life: 3000,
          });
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />
          {authorityList.includes(personelEkle) ? (
            <div className="col">
              <div className="card">
                <h5>{t("personel.personelEkleBaslik")}</h5>
                <p>
                {t("personel.personelEkleAciklama")}
                </p>
                <div className="grid">
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="personnelNameSurname">{t("personel.personelInputAdıSoyad")}</label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="personnelNameSurname"
                        value={data.name || ""}
                        onChange={(e) =>
                          setData({ ...data, name: e.target.value })
                        }
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="personnelEMail">{t("personel.personelInputEmail")}</label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="personnelEMail"
                        value={data.email || ""}
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="personnelEMail">{t("personel.personelInputSifre")}</label>
                      <Password
                        placeholder={t("global.placeholderInput")}
                        value={data.password || ""}
                        onChange={(e) =>
                          setData({ ...data, password: e.target.value })
                        }
                        toggleMask
                        promptLabel={"Parolanızı Giriniz"}
                        weakLabel={"Güçsüz"}
                        mediumLabel={"Orta"}
                        strongLabel={"Güçlü"}
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="personnelEMail">{t("personel.personelInputSifreTekrar")}</label>
                      <Password
                        placeholder={t("global.placeholderInput")}
                        value={data.password2 || ""}
                        onChange={(e) =>
                          setData({ ...data, password2: e.target.value })
                        }
                        toggleMask
                        promptLabel={"Parolanızı Giriniz"}
                        weakLabel={"Güçsüz"}
                        mediumLabel={"Orta"}
                        strongLabel={"Güçlü"}
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="personnelUsername">{t("personel.personelInputKullaniciAdi")}</label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="personnelUsername"
                        value={data.username || ""}
                        onChange={(e) =>
                          setData({ ...data, username: e.target.value })
                        }
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="personnelEMail">{t("personel.personelInputRol")}</label>
                      <Dropdown
                        value={data.role}
                        options={roleListData}
                        onChange={(e) =>
                          setData({
                            ...data,
                            role: e.target.value,
                            authorityList: e.target.value.authorityList,
                          })
                        }
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        placeholder={t("global.placeholderSelect")}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="personnelEMail">{t("personel.personelInputYetki")}</label>
                      <MultiSelect
                        value={data.authorityList}
                        options={authoritiesListData}
                        onChange={(e) =>
                          setData({ ...data, authorityList: e.target.value })
                        }
                        optionLabel="name"
                        placeholder={t("global.placeholderSelect")}
                        filter
                        maxSelectedLabels={3}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="personnelGroup">{t("personel.personelInputTakim")}</label>
                      <Dropdown
                        value={data.group}
                        options={groupListData}
                        onChange={(e) =>
                          setData({ ...data, group: e.target.value })
                        }
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        placeholder={t("global.placeholderSelect")}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-4"></div>
                  <div className="col-12 md:col-4"></div>
                  <div className="col-12 md:col-4">
                    <Button
                      label={t("global.btnSaveText")}
                      onClick={save}
                      className="p-button-success mr-2 mb-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col">
              <div className="card">
                <h5>Personel</h5>
                <p>
                  Aşağıda ki bilgileri doldurarak personel ekleyebilirsiniz.
                </p>
                <h2>Lütfen Yetkili Bir Kullanıcı İle Giriş Yapınız</h2>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Add, comparisonFn);

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// plugins
import moment from "moment";
import { useTranslation } from "react-i18next";

// Prime Component
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { confirmPopup } from "primereact/confirmpopup";
import { FilterMatchMode, FilterOperator } from "primereact/api";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { PersonnelGetList, PersonnelDetele } from "../../service/personnel";

/*Yetkilendirme ve Rol*/
import {
  personelListesi,
  personelDetay,
  momentDateFormat,
  personelSilme,
} from "../../constants/string";

import { JwtHelper } from "../../plugins/jwtHelper";

const List = () => {
  const { t } = useTranslation();
  const toast = useRef(null);
  const authorityList = JwtHelper.getAuthority(); //Yetkileri getirir
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    PersonnelGetList().then((res) => {
      setIsLoading(false);
      setData(res);
    });
    initFilters1();
  }, []);

  //Listelemeye düzenleme ve silme butonunu eklediğimiz kısım
  const actionBodyTemplate = (rowData) => {
    if (authorityList.includes(personelDetay)) {
      return (
        <React.Fragment>
          <Link
            className="p-button-rounded p-button-success mr-2"
            to={`/personel/${rowData.id}`}
          >
            <Button
              icon="pi pi-pencil"
              className="p-button-rounded p-button-success mr-2"
            />
          </Link>

          {authorityList.includes(personelSilme) && (
            <Button
              icon="pi pi-trash"
              className="p-button-rounded p-button-danger  mr-2"
              tooltip="Personel Sil"
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              onClick={() => {
                confirm2(rowData.id);
              }}
            />
          )}
        </React.Fragment>
      );
    }
    return "";
  };

  const momentBodyTemplate = (rowData) => {
    return moment(rowData.createdDate).format(momentDateFormat);
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };
  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      email: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue1("");
  };
  const clearFilter1 = () => {
    initFilters1();
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label={t("global.tableAramayiTemizle")}
          className="p-button-outlined"
          onClick={clearFilter1}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder={t("global.tableGenelArama")}
          />
        </span>
      </div>
    );
  };

  //Silme İşlemleri Başlar
  const accept = (id) => {
    //Silme işlemi evet diyince
    PersonnelDetele(id)
      .then((res) => {
        window.location.reload();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  const reject = () => {
    //Silme işlemi hayır diyince
  };

  //Silme Butonu Emin Misiniz Sorusu?
  const confirm2 = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Silme İşlemini Yapmak İstediğinize Emin misiniz?",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => accept(event),
      reject,
    });
  };
  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid">
          {authorityList.includes(personelListesi) ? (
            <div className="col-12">
              <div className="card">
                <h5>{t("personelListesi.personelListesiBaslik")}</h5>
                <p>{t("personelListesi.personelListesiAciklama")}</p>
                <DataTable
                  value={data}
                  responsiveLayout="scroll"
                  paginator
                  rows={10}
                  header={renderHeader1()}
                  emptyMessage={t("global.tableNoData")}
                  filters={filters1}
                  filterDisplay="menu"
                  dataKey="id"
                  globalFilterFields={["name", "email"]}
                  rowsPerPageOptions={[
                    10, 20, 30, 40, 50, 100, 150, 200, 250, 300,
                  ]}
                >
                  <Column field="name" header={t("personelListesi.tabloAdiSoyadi")}></Column>
                  <Column field="email" header={t("personelListesi.tabloEPosta")}></Column>
                  <Column field="role.name" header={t("personelListesi.tabloRol")}></Column>
                  <Column field="username" header={t("personelListesi.tabloKullaniciAdi")}></Column>
                  <Column
                    field="createdUser"
                    header={t("personelListesi.tabloOlusturan")}
                  ></Column>
                  <Column
                    field="createdDate"
                    body={momentBodyTemplate}
                    header={t("personelListesi.tabloOlusturmaTarihi")}
                  ></Column>
                  <Column
                    body={actionBodyTemplate}
                    exportable={false}
                    style={{ minWidth: "8rem" }}
                  ></Column>
                </DataTable>
              </div>
            </div>
          ) : (
            <div className="col-12">
              <div className="card">
                <h5>Personel Listesi</h5>
                <p>Personel listesinde yer alanlar aşağıda listelenmektedir.</p>
                <h2>Lütfen Yetkili Bir Kullanıcı İle Giriş Yapınız</h2>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(List, comparisonFn);

import React from 'react';

/*Prime Component*/


const RoleAndAuthorizationDokumantion = () => {
 
    return (
        <div className="grid p-fluid">
            <div className="col">
                <div className="card">
                    <h5>Rol ve Yetkilendirme Dökümantasyonu</h5>
                    <p>Aşağıda Rol ve Yetkilendirme ile ilgili dökümana ulaşabilirsiniz.</p>
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <ul>
                                <li>
                                    <b>Toplu Müşteri Ekle</b>: Toplu Müşteri Ekle menüsüne erişim sağlar. 
                                </li>
                                <li>
                                    <b>Not Görüntüleme</b>: Müşteri Açılan notların görüntülenmesi için kullanılan yetki. (Not görüntüleme yetkisi verilmediği takdirde kullanıcı hiçbir notu göremez.)
                                </li>
                                <li>
                                    <b>Not Ekleme</b>: Müşteriye not eklemek için verilen yetkidir. 
                                </li>
                                <li>
                                    <b>Anasayfa</b>: Anasayfa menüsünü görüntüleme ve anasayfa menüne erişmek için verilen yetkidir.
                                </li>
                                <li>
                                    <b>Takım Menü</b>: Takım eklemek ve listeyi görüntelemek, varolan takımın detayına gidilen menüye erişmek için verilen yetki. 
                                </li>
                                <li>
                                    <b>Takım Detay</b>: Takım listesinden ekle olan bir takımın detayına gitmek için kullanılan yetki. 
                                </li>
                                <li>
                                    <b>Takım Ekle</b>: Takımları oluşturabileceğimiz yetki.
                                </li>
                                <li>
                                    <b>Takım Listesi</b>: Daha önceden eklenmiş takımların, detayına, takımın içinde ki personel sayısına erişebileceğimiz üstünde değişiklikler yapabileceğimiz menüye erişim sağlar. 
                                </li>
                                <li>
                                    <b>Personel Menü</b>: Personel eklemek ve listeyi görüntelemek, varolan takımın detayına gidilen menüye erişmek için verilen yetki. 
                                </li>
                                <li>
                                    <b>Personel Listesi</b>: Daha önceden eklenmiş personellerin listesine erişim sağladığımız ve şifre, kullanıcı adı gibi personel özelliklerinin değiştirilmesi için verilen yetkidir. 
                                </li>
                                <li>
                                    <b>Personel Ekleme</b>: Yeni bir kullanıcı ekleyebilmesi için verilen yetkidir.
                                </li>
                                <li>
                                    <b>Personel Detay</b>: Oluşturulan personellerin detaylarına erişmek için verilen yetki. 
                                </li>
                                <li>
                                    <b>Lead Menü</b>: Lead ekle ve  Lead listesi alt menülerinin erişimi için verilen yetki.
                                </li>
                                <li>
                                    <b>Lead Listesi </b>: Daha önce eklenmiş olan leadlarin listemek ve üzerinde değişiklik yapmak için kullanılan menüye yetki verir. 
                                </li>
                                <li>
                                    <b>Lead Ekleme </b>: Yeni bir lead eklemek için kullanılan menü
                                </li>
                                <li>
                                    <b>Lead Detay </b>: Oluşturulan Lead'in detayına erişmek için verilen yetki.
                                </li>
                                <li>
                                    <b>Rol Menü </b>: Rol menüsünü, rol ekle ve rol listesi menüsünü görüntülemek için verilen yetkidir. 
                                </li>
                                <li>
                                    <b>Rol Ekleme </b>: Yeni bir rol eklemek (Satışçı, ret, yönetici vb. gibi) için kullanılan yetki. 
                                </li>
                                <li>
                                    <b>Rol Detay </b>: Oluşturulan rollerin detayına erişmek için verilen yetki. 
                                </li>
                                <li>
                                    <b>Rol Listesi </b>: Daha önce eklenmiş olan Rollerin listemek ve üzerinde değişiklik yapmak için kullanılan menüye yetki verir. 
                                </li>
                                <li>
                                    <b>Hatırlatıcı Ekle </b>: Müşteri için hatırlatıcı oluşturabilmesi için verilen yetkidir. 
                                </li>
                                <li>
                                    <b>Hatırlatıcı Listesi </b>: Oluşturulan hatırlatıcıları listeleyen menüye yetki verir. 
                                </li>
                                <li>
                                    <b>Rapor menü </b>: Bu yetki dahilinde crm üzerinden yapılmış raporlara erişim sağlanır. 
                                </li>
                                <li>
                                    <b>Yönetim Menü </b>: Tüm müşteri notları, Tüm hareket geçmişi, Sorumlu Kişi Geçmişi alt menülerine erişim sağlayan yetkidir.   
                                </li>
                                <li>
                                    <b>Tüm Müşteri Notları </b>: Kullanıcıların müşteri için açtığı bütün notları görüntüleme yetkisi sağlar. 
                                </li>
                                <li>
                                    <b>Tüm Hareket Geçmişi </b>: Kullanıcıların crm üzerinde yaptığı her hareketini görüntlemek için verilen yetki.
                                </li>
                                <li><b>Tüm Müşteri Sorumlu Kişi</b>: Müşterinin daha önceden kimde kayıtlı olduğunu veya mevcutta kimin üzerinde olduğunun görüntülenmesini sağlayan yetki.</li>
                                <li><b>Müşteri Menü </b>: Müşteri listesini, müşteri detay vb gibi menülerin erişmneni sağlamak için verilen yetki.</li>
                                <li><b>Müşteri Detay </b>: Müşterinin isim, soyisim gibi bilgilerinin detayını görmesini sağlayan yetki. </li>
                                <li><b>Müşteri Adı </b>: Müşterinin adını görmesini sağlayan yetki. </li>
                                <li><b>Müşteri Soyadı </b>: Müşterinin soydını görmesini sağlayan yetki. </li>
                                <li><b>Müşteri Ekle </b>: Tekli müşteri eklemek için verilen yetki. </li>
                                <li><b>Müşteri Listesi </b>: Yetkiye bazlı kendine ait veya toplu müşterilerini görebileceği yetkidir, bu yetki sayesinde listelenmiş yetkileri görebilir. </li>
                                <li><b>Müşteri E-Posta </b>: Müşterinin E-Posta adresini görmesini sağlayan yetki.</li>
                                <li><b>Müşteri Sorumlu Personel </b>: Müşterinin sorumlu personeli değiştirmek için verilken yetki. </li>
                                <li><b>Müşteri Telefon Numarası </b>: Müşterinin telefon numarasını görmesi için verilen yetki. </li>
                                <li><b>Müşteri Güncelle </b>: Müşteri üzerinde isim, soyisim vb gibi bilgileri görüntüyebilmesi ve güncelleyebilmesi için verilen yetki. </li>
                                <li><b>Müşteri Listesi Toplu Güncelleme </b>: Müşteri listesinde toplu seçim yaparak sorumlu personel değiştirme gibi yetkileri verir. </li>
                                <li><b>Müşteri Listesi Sorumlu Personel Geçmişi </b>: Müşterinin daha önce hangi kullanıcı da olduğunu görüntülemek için kullanılır. </li>
                                <li><b>Müşteri Listesi Lead Güncelleme </b>: Müşteri listesinde ki leadlerin değiştirebilmesi için verilen yetki. </li>
                                <li><b>Müşteri Listesi Atama Güncelleme (Personel) </b>: Müşterilerin farklı bir personele ataması için verilen yetki. </li>
                                <li><b>Google Sheets Listesi </b>: Marketing datalarının görüntülenmesi ve sorumlu personele yetkilendirilmesi kullanılır. </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(RoleAndAuthorizationDokumantion, comparisonFn);

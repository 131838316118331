import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

// plugins
import { useTranslation } from "react-i18next";

/*Prime Component*/
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { RoleGet, RolePut } from "../../service/role";
import { AuthoritiesGet } from "../../service/authorities";

/*Yetkilendirme ve Rol*/
import { rolDetay } from "../../constants/string";

import { JwtHelper } from "../../plugins/jwtHelper";

const Detail = () => {
  const { t } = useTranslation();
  const authorityList = JwtHelper.getAuthority(); //Yetkileri getirir
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [data, setData] = useState({
    name: "",
    authorityList: [],
  });
  const location = useLocation();
  const [pageId] = useState(location.pathname.split("/")[2]);
  const [authoritiesListData, setAuthoritiesListData] = useState([]); //Yetki listesi
  const [selectedCategories, setSelectedCategories] = useState(
    authoritiesListData.slice()
  );
  useEffect(() => {
    RoleGet(pageId).then((res) => {
      setData(res);
      setSelectedCategories(res.authorityList);
      //Yetki listesi
      AuthoritiesGet().then((res) => {
        setAuthoritiesListData(res);
        setIsLoading(false);
      });
    });
  }, [pageId]);
  const onCategoryChange = (e) => {
    let _selectedCategories = [...selectedCategories];
    if (e.checked) {
      _selectedCategories.push(e.value);
    } else {
      for (let i = 0; i < _selectedCategories.length; i++) {
        const selectedCategory = _selectedCategories[i];

        if (selectedCategory.id === e.value.id) {
          _selectedCategories.splice(i, 1);
          break;
        }
      }
    }

    setSelectedCategories(_selectedCategories);
  };

  const save = () => {
    setIsLoading(true);
    data.authorityList = selectedCategories;
    RolePut(data)
      .then((res) => {
        setIsLoading(false);

        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Rol Güncellendi",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />
          {authorityList.includes(rolDetay) ? (
            <div className="col">
              <div className="card">
                <h5>{t("rol.rolDetayBaslik")}</h5>
                <p>{t("rol.rolDetayAciklama")}</p>
                <div className="grid">
                  <div className="col-12 md:col-12">
                    <div className="field">
                      <label htmlFor="teamName">Rol Adı</label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="teamName"
                        value={data.name || ""}
                        onChange={(e) =>
                          setData({ ...data, name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="grid">
                    {authoritiesListData.map((category) => {
                      return (
                        <div key={category.id} className="col-12 md:col-4">
                          <Checkbox
                            inputId={category.id}
                            name="category"
                            value={category}
                            onChange={onCategoryChange}
                            checked={selectedCategories.some(
                              (item) => item.id === category.id
                            )}
                          />
                          <label
                            style={{ marginLeft: 7 }}
                            htmlFor={category.id}
                          >
                            {category.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 md:col-4"></div>
                  <div className="col-12 md:col-4"></div>
                  <div className="col-12 md:col-4">
                    <Button
                      label={t("global.btnUpdateText")}
                      onClick={save}
                      className="p-button-success mr-2 mb-2"
                      disabled={data.name ? false : true}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-12">
              <div className="card">
                <h5>Rol</h5>
                <p>Seçtiğiniz Rol'ün Bilgilerini Aşağıda Görebilirsiniz.</p>
                <h2>Lütfen Yetkili Bir Kullanıcı İle Giriş Yapınız</h2>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Detail, comparisonFn);

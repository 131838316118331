import {api} from '../constants/string';
import {HTTP} from "../axios/axios";


export function NotificationGetNotReading(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/notifications/by-user/${id}/not-reading`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}
export function NotificationGet(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/notifications/by-user/${id}`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}
import React, { useState, useRef, useEffect } from "react";

// plugins
import { useTranslation } from "react-i18next";

/*Prime Component*/
import { Toast } from "primereact/toast";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { LeadPost } from "../../service/lead";

/*Yetkilendirme ve Rol*/
import { leadEkle } from "../../constants/string";

import { JwtHelper } from "../../plugins/jwtHelper";
import { Chart } from "primereact/chart";
import AdvertisementPieChart from "./advertisementPieChart";
import PersonnelIdLeadReport from "./personnelIdLeadReport";

const GeneralReport = () => {
  const { t } = useTranslation();

  const authorityList = JwtHelper.getAuthority(); //Yetkileri getirir
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const data = {
      labels: ["Q1", "Q2", "Q3", "Q4", "Q5", "Q6"],
      datasets: [
        {
          label: "Lead",
          data: [540, 325, 702, 620, 300, 10],
          backgroundColor: [
            "#42A5F5",
            "#EC407A",
            "#AB47BC",
            "#7E57C2",
            "#66BB6A",
            "#FFCA28",
            "#26A69A",
            "#e8dcb0",
            "#d470a2",
            "#787ef4",
            "#f90bab",
            "#dfa700",
            "#cc3c63",
            "#82c9b6",
            "#ccbbbb",
            "#ff8b94",
            "#cc4668",
          ],
          borderColor: [
            "#42A5F5",
            "#EC407A",
            "#AB47BC",
            "#7E57C2",
            "#66BB6A",
            "#FFCA28",
            "#26A69A",
            "#e8dcb0",
            "#d470a2",
            "#787ef4",
            "#f90bab",
            "#dfa700",
            "#cc3c63",
            "#82c9b6",
            "#ccbbbb",
            "#ff8b94",
            "#cc4668",
          ],
          borderWidth: 1,
        },
      ],
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />

          <div className="col-6">
            <div className="card">
              <h5>Lead Durumları Genel Bakış</h5>
              <Chart type="bar" data={chartData} options={chartOptions} />
            </div>
          </div>
          <div className="col-6">
            <AdvertisementPieChart />
          </div>
          <div className="col-6">
            <PersonnelIdLeadReport />
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(GeneralReport, comparisonFn);

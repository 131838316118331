import {api} from '../constants/string';
import {HTTP} from "../axios/axios";

export function PersonnelPost(data) {
  return new Promise((res, rej) =>
      HTTP.post(`${api}/users`, data)
          .then(response => {
              return res(response.data);
          })
          .catch((error) => {
               
              rej(error);
              //return alert(error.response.data.Errors[0].Message);
          }),
  );
}

export function PersonnelPut(data) {
  return new Promise((res, rej) =>
      HTTP.put(`${api}/users`, data)
          .then(response => {
              return res(response.data);
          })
          .catch((error) => {
               
              rej(error);
          }),
  );
}

export function PersonnelGet(id) {
  return new Promise((res, rej) =>
      HTTP.get(`${api}/users/${id}`)
          .then(response => {
              return res(response.data);
          })
          .catch((error) => {
               
              rej(error);
          }),
  );
}

export function PersonnelGetList() {
  return new Promise((res, rej) =>
      HTTP.get(`${api}/users`)
          .then(response => {
              return res(response.data);
          })
          .catch((error) => {
               
              rej(error);
          }),
  );
}

export function PersonnelDetele(id) {
    return new Promise((res, rej) =>
        HTTP.delete(`${api}/users/${id}`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

import React, { useState, useEffect, useRef } from "react";

// plugins
import moment from "moment";

// Prime Component
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { Mt4TradesModifyTimeDescGetList } from "../../service/mt4Service";
import { momentDateFormat } from "../../constants/string";

const TradesModifyTimeDescGetList = () => {
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mt4UsersList, setMt4UsersList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" },
      "country.name": { value: "", matchMode: "contains" },
      company: { value: "", matchMode: "contains" },
      "representative.name": { value: "", matchMode: "contains" },
    },
  });

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      Mt4TradesModifyTimeDescGetList(selectedPage, lazyParams.rows).then(
        (data) => {
          console.log("data", data);
          setTotalRecords(data.totalElements);
          setMt4UsersList(data);
          setLoading(false);
          setIsLoading(false);
        }
      );
    }, Math.random() * 1000 + 250);
  };

  const onPage = (event) => {
    setLazyParams(event);
    lazyParams.rows = event.rows;
    setSelectedPage(event.page + 1);
  };

  const onSort = (event) => {
    setLazyParams(event);
  };
  const openTimeBodyTemplate = (rowData) => {
    return moment(rowData.openTime).format(momentDateFormat);
  };
  const closeTimeBodyTemplate = (rowData) => {
    return moment(rowData.closeTime).format(momentDateFormat);
  };
  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <div className="col-12">
            <div className="card">
              <h5>MT4 Kullanıcı Zaman Değişikliği Listesi</h5>
              <p>
                MT4 Kullanıcı Zaman Değişikliği listesinde yer alanlar aşağıda
                listelenmektedir.
              </p>
              <DataTable
                value={mt4UsersList.content}
                lazy
                responsiveLayout="scroll"
                dataKey="id"
                paginator
                first={lazyParams.first}
                rows={lazyParams.rows}
                rowsPerPageOptions={[
                  10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 350, 400, 450,
                  500, 1000,
                ]}
                totalRecords={totalRecords}
                onPage={onPage}
                onSort={onSort}
                sortField={lazyParams.sortField}
                sortOrder={lazyParams.sortOrder}
                loading={loading}
              >
                <Column field="volume" header="volume"></Column>
                <Column field="tp" header="tp"></Column>
                {/* <Column field="timestamp" header="timestamp"></Column> */}
                <Column field="ticket" header="ticket"></Column>
                {/* <Column field="taxes" header="taxes"></Column> */}
                <Column field="symbol" header="symbol"></Column>
                <Column field="swaps" header="swaps"></Column>
                <Column field="sl" header="sl"></Column>
                {/* <Column field="reason" header="reason"></Column> */}
                <Column field="profit" header="profit"></Column>
                <Column header="openTime" body={openTimeBodyTemplate} />
                <Column field="openPrice" header="openPrice"></Column>
                {/* <Column header="modifyTime" body={modifyTimeBodyTemplate} /> */}
                {/* <Column field="marginRate" header="marginRate"></Column> */}
                {/* <Column field="magic" header="magic"></Column> */}
                <Column field="login" header="login"></Column>
                {/* <Column field="internalId" header="internalId"></Column> */}
                {/* <Column field="gwVolume" header="gwVolume"></Column> */}
                {/* <Column field="gwOpenPrice" header="gwOpenPrice"></Column> */}
                {/* <Column field="gwClosePrice" header="gwClosePrice"></Column> */}

                {/* <Column header="expiration" body={expirationBodyTemplate} /> */}
                {/* <Column field="digits" header="digits"></Column> */}
                {/* <Column field="convRate2" header="convRate2"></Column> */}
                {/* <Column field="convRate1" header="convRate1"></Column> */}
                {/* <Column field="commissionAgent" header="commissionAgent"></Column> */}
                <Column field="commission" header="commission"></Column>
                <Column field="comment" header="comment"></Column>
                {/* <Column field="cmd" header="cmd"></Column> */}
                <Column header="closeTime" body={closeTimeBodyTemplate} />
                <Column field="closePrice" header="closePrice"></Column>
              </DataTable>
              <span className="mr-3 mt-3" style={{ display: "inline-block" }}>
                Toplam Kayıt Sayısı: {mt4UsersList.totalElements}
              </span>
              <span className="mr-3 mt-3" style={{ display: "inline-block" }}>
                Toplam Sayfa Sayısı: {mt4UsersList.totalPages}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TradesModifyTimeDescGetList, comparisonFn);

import React, { useState, useRef } from 'react';

/*Prime Component*/
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';

/*Component*/
import ContentLoading from '../loading/ContentLoading';

/*Service*/
import { NotesPost } from '../../service/note';

const NotesAdd = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);
    const [noteData, setNoteData] = useState(
        {
            title:'',
            content:'',
            customer:{}
        }
    );

    const notesSave = () => {
        setIsLoading(true)
        noteData.customer.id = props.customerData.id;
        NotesPost(noteData).then(res => {
            setIsLoading(false);
            setNoteData({
                title:'',
                content:'',
                customer:{}
            })
            
            props.fastNoteAddDisplayDataHandle(false)
        }).catch(err => {
            setIsLoading(false);
            
            toast.current.show({severity: 'error', summary: 'Hata', detail: `${err.response.data}`, life: 3000});
        })
    }
    return (
        <>
            {isLoading ? (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <ContentLoading/>
                        </div>
                    </div>
                </div>
            ):(
                <div className="grid p-fluid">
                    <Toast ref={toast} />
                    <div className="col">
                        <div className="card">
                            <h5>{props.customerData.name} {props.customerData.surname} müşterisine not ekle</h5>
                            <p>Aşağıda ki bilgileri doldurarak {props.customerData.name} {props.customerData.surname} müşterisine not ekleyebilirsiniz.</p>
                            <div className="grid">
                                <div className='col-12'>
                                    <div className="field">
                                        <label htmlFor="teamCode">Müşteri Notu</label>
                                            <InputText placeholder="Lütfen Not Başlığını Giriniz" type="text" id="customerNoteTitle" value={noteData.title || ''} onChange={(e) => setNoteData({...noteData, title:e.target.value })} />
                                        <InputTextarea className='mt-2' placeholder="Lütfen Not İçeriği Giriniz" value={noteData.content} onChange={(e) => setNoteData({...noteData, content:e.target.value })} rows={5} cols={30} />
                                    </div>
                                    <div className="field">
                                        <Button label="Müşteri Notu Ekle" onClick={notesSave} className="p-button-success mr-2 mb-2" disabled={noteData.content ? false : true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}



export default NotesAdd;
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { useState, useRef, useEffect } from "react";

// plugins
import { useTranslation } from "react-i18next";

/*Prime Component*/
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { CustomersPost } from "../../service/customer";
import { PersonnelGetList } from "../../service/personnel";
import { LeadGetList } from "../../service/lead";
import { SourceGetList } from "../../service/source";
import { NotesPost } from "../../service/note";

/*Yetkilendirme ve Rol*/
import { musteriEkle, satisMusteriEkle } from "../../constants/string";

import { JwtHelper } from "../../plugins/jwtHelper";
import { UlkeJson } from "../../constants/ulke";

const Add = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const [metaIdList, setMetaIdList] = useState([{ login: "" }]);
  const [data, setData] = useState({
    name: "",
    surname: "",
    phoneNumberList: [],
    email: "",
    leadState: {},
    user: {},
    phone: "",
    phone2: "",
    source: "",
    source2: "",
    source3: "",
    metaId: "",
    sourceSelected: "",
    areaCode: "+90",
    areaCode2: "+90",
  });
  const authorityList = JwtHelper.getAuthority(); //Yetkileri getirir
  const [noteData, setNoteData] = useState({
    title: "",
    content: "",
    customer: {},
  });
  const [personnelList, setPersonnelList] = useState({});
  const [leadList, setleadListList] = useState({});
  const [sourceDataList, setSourceListList] = useState([]);

  useEffect(() => {
    PersonnelGetList().then((res) => {
      //Personel Listesini Getirelim
      setPersonnelList(res);
      LeadGetList().then((res) => {
        //Lead Listesini Getirelim
        setIsLoading(false);
        setleadListList(res);
      });
      SourceGetList().then((res) => {
        //kaynak Listesini Getirelim
        setIsLoading(false);
        setSourceListList(res);
      });
    });
  }, []);

  const save = () => {
    function DefaultModelCreator(data) {
      return {
        id: data.id,
        phoneNumber: data.phoneNumber,
      };
    }
    data.metaAccountList = metaIdList;
    setIsLoading(true);
    if (authorityList.includes(satisMusteriEkle)) {
      if (
        data.source == "" ||
        data.source == null ||
        data.source == undefined
      ) {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Kaynak Seçiniz",
          life: 3000,
        });
        setIsLoading(false);
        return;
      }
    }
    if (
      !(data.phone === "" || data.phone === undefined || data.phone === null)
    ) {
      data.phoneNumberList.push(
        DefaultModelCreator({
          id: "",
          phoneNumber: data.phone,
          areaCode: data.areaCode,
        })
      );
    }
    if (
      !(data.phone2 === "" || data.phone2 === undefined || data.phone2 === null)
    ) {
      data.phoneNumberList.push(
        DefaultModelCreator({
          id: "",
          phoneNumber: data.phone2,
          areaCode: data.areaCode2,
        })
      );
    }
    CustomersPost(data)
      .then((res) => {
        setIsLoading(false);
        noteData.customer = res;
        if (noteData.title !== "") {
          NotesPost(noteData)
            .then((res) => {
              setIsLoading(false);

              toast.current.show({
                severity: "success",
                summary: "Başarılı",
                detail: "Başarılı Şekilde Not Eklendi",
                life: 3000,
              });
            })
            .catch((err) => {
              setIsLoading(false);

              toast.current.show({
                severity: "error",
                summary: "Hata",
                detail: `${err.response.data}`,
                life: 3000,
              });
            });
        }
        setData({
          name: "",
          surname: "",
          phoneNumberList: [],
          email: "",
          leadState: null,
          user: null,
          phone: "",
          phone2: "",
        });

        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Müşteri Eklendi",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };
  //Dropdown Search Code Start
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const UlkeOptionTemplate = (option) => {
    return (
      <div>
        ({option.dial_code}) {option.name}
      </div>
    );
  };

  const phoneReplace = (e, type) => {
    const phone = e.replace(/ /g, "");
    var phone2 = e;
    if (phone[0] === "0") {
      var phone2 = phone.substring(1);
    }
    if (phone[0] + phone[1] === "90") {
      var phone2 = phone.substring(2);
    }
    if (phone[0] + phone[1] + phone[2] === "+90") {
      var phone2 = phone.substring(3);
    }
    if (type === "phone1") {
      setData({ ...data, phone: phone2 });
    } else {
      setData({ ...data, phone2: phone2 });
    }
  };
  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.name}</div>
      </div>
    );
  };

  // handle input change
  const handleMetaIdInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...metaIdList];
    list[index][name] = value;
    setMetaIdList(list);
  };

  // handle click event of the Remove button
  const handleMetaIdRemoveClick = (index) => {
    const list = [...metaIdList];
    list.splice(index, 1);
    setMetaIdList(list);
  };

  // handle click event of the Add button
  const handleMetaIdAddClick = () => {
    setMetaIdList([...metaIdList, { login: "" }]);
  };
  console.log("DATA", data);

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />
          {authorityList.includes(musteriEkle) ? (
            <div className="col">
              <div className="card">
                <h5>{t("musteri.baslik")}</h5>
                <p>{t("musteri.aciklama")}</p>
                <div className="grid">
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="teamName">{t("musteri.adi")}</label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="teamName"
                        value={data.name || ""}
                        onChange={(e) =>
                          setData({ ...data, name: e.target.value })
                        }
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="customerEmail">
                        {t("musteri.email")}
                      </label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="customerEmail"
                        value={data.email || ""}
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="personnelEMail">
                        {t("musteri.sorumlu")}
                      </label>
                      <Dropdown
                        value={data.user}
                        options={personnelList}
                        onChange={(e) => setData({ ...data, user: e.value })}
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        placeholder={t("global.placeholderSelect")}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="personnelEMail">
                        {t("musteri.lead")}
                      </label>
                      <Dropdown
                        value={data.leadState}
                        options={leadList}
                        onChange={(e) =>
                          setData({ ...data, leadState: e.value })
                        }
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        placeholder={t("global.placeholderSelect")}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                      />
                    </div>
                    {metaIdList.map((x, i) => {
                      return (
                        <div className="box">
                          <div className="field">
                            <label htmlFor="name" className="d-block">
                              {t("musteri.metaId")}
                            </label>
                            <InputText
                              style={{ width: "80%" }}
                              placeholder={t("global.placeholderInput")}
                              type="text"
                              name="login"
                              value={x.login}
                              onChange={(e) => handleMetaIdInputChange(e, i)}
                            />
                            {metaIdList.length - 1 === i && (
                              <Button
                                style={{ top: 7 }}
                                icon="pi pi-plus"
                                className="mr-2 mb-2 p-button-success"
                                onClick={handleMetaIdAddClick}
                              ></Button>
                            )}
                            {metaIdList.length !== 1 && (
                              <Button
                                style={{ top: 7 }}
                                icon="pi pi-trash"
                                className="mr-2 mb-2 p-button-danger"
                                onClick={() => handleMetaIdRemoveClick(i)}
                              ></Button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="teamCode">{t("musteri.soyadi")}</label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="teamCode"
                        value={data.surname || ""}
                        onChange={(e) =>
                          setData({ ...data, surname: e.target.value })
                        }
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="personnelEMail">
                        {t("musteri.telefonKodu")}
                      </label>
                      <Dropdown
                        value={data.areaCode}
                        options={UlkeJson}
                        onChange={(e) =>
                          setData({ ...data, areaCode: e.value })
                        }
                        optionValue="dial_code"
                        optionLabel="name"
                        itemTemplate={UlkeOptionTemplate}
                        filter
                        showClear
                        filterBy="name"
                        placeholder={t("global.placeholderSelect")}
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="customerPhone">
                        {t("musteri.telefon")}
                      </label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="customerPhone"
                        value={data.phone || ""}
                        onChange={(e) => phoneReplace(e.target.value, "phone1")}
                      />
                    </div>{" "}
                    <div className="field">
                      <label htmlFor="personnelEMail">
                        {t("musteri.telefonKodu")}
                      </label>
                      <Dropdown
                        value={data.areaCode2}
                        options={UlkeJson}
                        onChange={(e) =>
                          setData({ ...data, areaCode2: e.value })
                        }
                        optionValue="dial_code"
                        optionLabel="name"
                        itemTemplate={UlkeOptionTemplate}
                        filter
                        showClear
                        filterBy="name"
                        placeholder={t("global.placeholderSelect")}
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="customerPhone2">
                        {t("musteri.telefon2")}
                      </label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="customerPhone2"
                        value={data.phone2 || ""}
                        onChange={(e) => phoneReplace(e.target.value, "phone2")}
                      />
                    </div>
                    {authorityList.includes(satisMusteriEkle) ? (
                      <div className="field">
                        <label htmlFor="personnelEMail">
                          {t("musteri.kaynak")}
                        </label>
                        <Dropdown
                          value={data.source}
                          options={sourceDataList}
                          onChange={(e) =>
                            setData({ ...data, source: e.value })
                          }
                          optionValue="name"
                          optionLabel="name"
                          placeholder={t("global.placeholderSelect")}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="field">
                          <label htmlFor="teamSource">
                            {t("musteri.kaynak")}
                          </label>
                          <InputText
                            placeholder={t("global.placeholderInput")}
                            type="text"
                            id="teamSource"
                            value={data.source}
                            onChange={(e) =>
                              setData({ ...data, source: e.target.value })
                            }
                          />
                        </div>
                        <div className="field">
                          <label htmlFor="teamSource">
                            {t("musteri.kaynak")} 2
                          </label>
                          <InputText
                            placeholder={t("global.placeholderInput")}
                            type="text"
                            id="teamSource"
                            value={data.source2}
                            onChange={(e) =>
                              setData({ ...data, source2: e.target.value })
                            }
                          />
                        </div>
                        <div className="field">
                          <label htmlFor="customerSource3">
                            {t("musteri.kaynak")} 3
                          </label>
                          <InputText
                            placeholder={t("global.placeholderInput")}
                            type="text"
                            id="customerSource3"
                            value={data.source3}
                            onChange={(e) =>
                              setData({ ...data, source3: e.target.value })
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-12">
                    <div className="field">
                      <label htmlFor="teamCode">{t("musteri.not")}</label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="customerNoteTitle"
                        value={noteData.title || ""}
                        onChange={(e) =>
                          setNoteData({ ...noteData, title: e.target.value })
                        }
                      />
                      <InputTextarea
                        className="mt-2"
                        placeholder={t("global.placeholderInput")}
                        value={noteData.content}
                        onChange={(e) =>
                          setNoteData({ ...noteData, content: e.target.value })
                        }
                        rows={5}
                        cols={30}
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-4"></div>
                  <div className="col-12 md:col-4"></div>
                  <div className="col-12 md:col-4">
                    <Button
                      label={t("global.btnSaveText")}
                      onClick={save}
                      className="p-button-success mr-2 mb-2"
                      disabled={data.phone ? false : true}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col">
              <div className="card">
                <h5>Müşteri</h5>
                <p>Aşağıda ki bilgileri doldurarak müşteri ekleyebilirsiniz.</p>
                <h2>Lütfen Yetkili Bir Kullanıcı İle Giriş Yapınız</h2>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Add, comparisonFn);

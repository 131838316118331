import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";

// plugins
import moment from "moment";
import { useTranslation } from "react-i18next";

/*Prime Component*/
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { NotesUserGet } from "../../service/note";

/*Yetkilendirme ve Rol*/
import { momentDateFormat } from "../../constants/string";

const UserNote = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [allNoteData, setAllNoteData] = useState([]); // Tüm Müşteri Notları Listesi
  useEffect(() => {
    var decoded = jwt_decode(localStorage.token);
    NotesUserGet(decoded.id).then((res) => {
      setIsLoading(false);
      setAllNoteData(res);
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="p-fluid">
          <Toast ref={toast} />
          <div className="col-12">
            <h5>{t("anasayfa.kendiActigimNotlarBaslik")}</h5>
            <p>{t("anasayfa.kendiActigimNotlarAciklama")}</p>
            <div className="grid">
              {allNoteData.map((item, i) => {
                return (
                  <div className="col-12 md:col-4" key={i}>
                    <Card
                      title={item.title}
                      subTitle={t("anasayfa.tumMusteriNotlariOlusturan") + item.createdUser}
                      style={{ width: "100%" }}
                    >
                      <p className="m-0" style={{ lineHeight: "1.5" }}>
                        {item.content}
                      </p>
                      <div className="p-card-subtitle mt-4">
                      {t("anasayfa.tumMusteriNotlariOlusturmaTarihi")}<br></br>
                        <b>
                          {moment(item.customer.createdDate).format(
                            momentDateFormat
                          )}
                        </b>
                      </div>
                      <div className="p-card-subtitle mt-1">
                      {t("anasayfa.tumMusteriNotlariMusteri")}<br></br>
                        <b>{item.customer.name + item.customer.surname}</b>
                      </div>
                      <Link
                        className="p-button-info mr-2"
                        to={`/musteri/${item.customer.id}`}
                      >
                        <Button
                          label={t("anasayfa.kendiActigimNotlarMusteriDetay")}
                          className=" p-button-info mr-2 mt-3"
                        />
                      </Link>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UserNote, comparisonFn);

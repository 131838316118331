import {api} from '../constants/string';
import {HTTP} from "../axios/axios";

export function WhatsappSendCustomerMessage(data) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/whatsapp/send/message`, data)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}

export function WhatsappNotificationGetCustomerMessage(data) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/whatsapp/get/notification/${data}`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}

export function WhatsappCustomerGetMessage(to) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/whatsapp/get/message?to=${to}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}

export function WhatsappSendCustomerImage(data,img) {
    const formData = new FormData();
    formData.append("file",img);
    formData.append("to",data.to);
    formData.append("caption","");
    // formData.append("userId",data.userId);
    // formData.append("customerId",data.customerId);
    return new Promise((res, rej) =>
        HTTP.post(`${api}/whatsapp/send/image`, formData,{headers:{"Content-Type": "multipart/form-data"}})
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}
export function WhatsappSendCustomerFile(data,file) {
    const formData = new FormData();
    formData.append("file",file);
    formData.append("to",data.to);
    return new Promise((res, rej) =>
        HTTP.post(`${api}/whatsapp/send/document`, formData,{headers:{"Content-Type": "multipart/form-data"}})
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}

export function WhatsappSendCustomerVoice(file,phone) {
    const formData = new FormData();
    formData.append("file",file.blob);
    formData.append("to",phone);

    return new Promise((res, rej) =>
        HTTP.post(`${api}/whatsapp/send/voice`, formData,{headers:{"Content-Type": "multipart/form-data"}})
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// plugins
import moment from "moment";
import { useTranslation } from "react-i18next";

// Prime Component
import { DataTable } from "primereact/datatable";
import { confirmPopup } from "primereact/confirmpopup";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { TicketGetList, TicketDetele } from "../../service/ticket";
import { momentDateFormat } from "../../constants/string";

const List = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [data, setData] = useState([]); //Takım Listesi
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    TicketGetList().then((res) => {
      setIsLoading(false);
      setData(res);
    });
    initFilters1();
  }, []);

  //Listelemeye düzenleme ve silme butonunu eklediğimiz kısım
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Link
          className="p-button-rounded p-button-success"
          to={`/ticket/${rowData.id}`}
        >
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success mr-2"
          />
        </Link>
      </React.Fragment>
    );
  };
  const importanceLevelBodyTemplate = (rowData) => {
    if (rowData.importanceLevel == "LOW") {
      return <span className="p-tag p-tag-success">Düşük</span>;
    }
    if (rowData.importanceLevel == "MEDIUM") {
      return <span className="p-tag p-tag-success">Orta</span>;
    }
    if (rowData.importanceLevel == "HIGH") {
      return <span className="p-tag p-tag-success">Yüksek</span>;
    }
    return "";
  };
  const statusBodyTemplate = (rowData) => {
    if (rowData.status == "OPEN") {
      return <span className="p-tag p-tag-success">Açık</span>;
    }
    if (rowData.status == "IN_PROGRESS") {
      return <span className="p-tag p-tag-success">İşleme Alındı</span>;
    }
    if (rowData.status == "FINISHED") {
      return <span className="p-tag p-tag-success">Tamamlandı</span>;
    }
    return "";
  };

  const momentCreatedBodyTemplate = (rowData) => {
    if (rowData.createdDate === null) return "";
    return moment(rowData.createdDate).format(momentDateFormat);
  };

  const momentUpdatedBodyTemplate = (rowData) => {
    if (rowData.updatedDate === null) return "";
    return moment(rowData.updatedDate).format(momentDateFormat);
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };
  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      createdUser: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue1("");
  };
  const clearFilter1 = () => {
    initFilters1();
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label={t("global.tableAramayiTemizle")}
          className="p-button-outlined"
          onClick={clearFilter1}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder={t("global.tableGenelArama")}
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();

  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>{t("ticketListesi.ticketBaslik")}</h5>
              <p>{t("ticketListesi.ticketAciklama")}.</p>
              <DataTable
                value={data}
                responsiveLayout="scroll"
                paginator
                rows={10}
                header={header1}
                emptyMessage={t("global.tableNoData")}
                filters={filters1}
                filterDisplay="menu"
                dataKey="id"
                globalFilterFields={["name", "createdUser"]}
                rowsPerPageOptions={[
                  10, 20, 30, 40, 50, 100, 150, 200, 250, 300,
                ]}
              >
                <Column
                  field="importanceLevel"
                  header={t("ticketListesi.oncelik")}
                  body={importanceLevelBodyTemplate}
                ></Column>
                <Column
                  field="status"
                  header={t("ticketListesi.durum")}
                  body={statusBodyTemplate}
                ></Column>
                <Column field="title" header={t("ticketListesi.talepBasligi")}></Column>
                <Column
                  field="createdUser"
                  header={t("ticketListesi.olusturanKullanici")}
                ></Column>
                <Column
                  field={t("takimListesi.takimListesiBaslik")}
                  header={t("ticketListesi.olusturmaTarihi")}
                  body={momentCreatedBodyTemplate}
                ></Column>

                <Column
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{ minWidth: "8rem" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(List, comparisonFn);

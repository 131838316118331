/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from "react";
import {
    CustomerInfoAggregation,
} from "../../service/customer";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import moment from "moment";
import {momentDateFormat} from "../../constants/string";
import {Button} from "primereact/button";
import ContentLoading from "../loading/ContentLoading";

const CustomerInfo = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [downloadIconName, setDownloadIconName] = useState("pi pi-file-excel");
    const dt = useRef(null);
    //pi pi-spin pi-spinner

    useEffect(() => {
        CustomerInfoAggregation().then(value => {
            setData(value);
            setIsLoading(false);
        });
    }, []);

    const displayCreatedDate = (rowData) => {
        return moment(rowData.createdDate).format(momentDateFormat);
    }
    const displayUpdatedtedDate = (rowData) => {
        return moment(rowData.updatedDate).format(momentDateFormat);
    }

    // const exportExcel = async () => {
    //     setDownloadIconName("pi pi-spin pi-spinner")
    //     CustomerInfoAggregationExcel().then(response => {
    //         console.log("response", response);
    //         let url = window.URL.createObjectURL(response);
    //         let a = document.createElement("a");
    //         document.body.appendChild(a);
    //         a.setAttribute("style", "display:none");
    //         a.download = `musteri-listesi.xlsx`;
    //         a.href = url;
    //         a.click();
    //         window.URL.revokeObjectURL(url);
    //         a.remove();
    //     }).finally(() => setDownloadIconName("pi pi-file-excel"));
    // }

    const exportExcelButton = () => {
        return (
            <Button label="İndir" iconPos="left" className="p-button-success" icon={downloadIconName}
                    onClick={exportCsv}/>
        );
    }

    const exportCsv = () => {
        dt.current.exportCSV();
    }

    return (

        isLoading ? (
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <ContentLoading/>
                    </div>
                </div>
            </div>
        ) : (
            <div className="grid p-fluid">
                <div className="col-12">
                    <div className="card">
                        <h5>Müşteri Bilgileri Raporu</h5>
                        <DataTable value={data}
                                   dataKey="id"
                                   paginator
                                   rows={10}
                                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                   ref={dt}>
                            <Column field="id" header="ID"/>
                            <Column field="createdDate" header="Üretim zamanı" body={displayCreatedDate}/>
                            <Column field="createdUser" header="Oluşturan kullanıcı"/>
                            <Column field="updatedDate" header="Güncelleme zamanı" body={displayUpdatedtedDate}/>
                            <Column field="updatedUser" header="Güncelleyen kullanıcı"/>
                            <Column field="name" header="İsim"/>
                            <Column field="email" header="E-posta"/>
                            <Column field="phoneNumber" header="Telefon numarası"/>
                            <Column field="leadState" header="Lead durumu"/>
                            <Column field="assigneeUser" header="Atanan kullanıcı"/>
                            <Column field="source" header="Kaynak"/>
                            <Column field="source2" header="Kaynak - 2"/>
                        </DataTable>
                        <div className="col-1">
                            {
                                exportExcelButton()
                            }
                        </div>
                    </div>
                </div>
            </div>)


    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CustomerInfo, comparisonFn);
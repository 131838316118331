import React, { useState, useRef } from 'react';

/*Prime Component*/
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';

/*Component*/
import ContentLoading from '../loading/ContentLoading';

/*Service*/
import { ReminderCustomerPost } from '../../service/reminder';

const ReminderAdd = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);

    const [reminderPostData, setReminderPostData] = useState({//Hatırlatıcı Model
        title:'',
        description:'',
        startDate:new Date(),
        customer:{
            id:null
        }
    });
    const reminderSave = () => {
        reminderPostData.customer.id = props.customerData.id;
        ReminderCustomerPost(reminderPostData).then(res => {
            setIsLoading(false)
            setReminderPostData({
                title:'',
                description:'',
                startDate:new Date(),
                customer:{
                    id:null
                }
            })
            
            props.fastRemirderAddDisplayDataHandle(false)
        }).catch(err => {
            setIsLoading(false);
            
            toast.current.show({severity: 'error', summary: 'Hata', detail: `${err.response.data}`, life: 3000});
        })
        
    }
    return (
        <>
            {isLoading ? (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <ContentLoading/>
                        </div>
                    </div>
                </div>
            ):(
                <div className="grid p-fluid">
                    <Toast ref={toast} />
                    <div className="col">
                        <div className="card">
                            <h5>{props.customerData.name} {props.customerData.surname} müşterisine hatırlatıcı ekle</h5>
                            <p>Aşağıda ki bilgileri doldurarak {props.customerData.name} {props.customerData.surname} müşterisine hatırlatıcı ekleyebilirsiniz.</p>
                            <div className="grid">
                                <div className='col-12'>
                                    <div className="field">
                                        <label htmlFor="teamName">Hatırlatıcı Adı</label>
                                        <InputText
                                            placeholder="Lütfen Hatırlatıcı Adını Giriniz" type="text" id="teamName" value={reminderPostData.title || ''} onChange={(e) => setReminderPostData({...reminderPostData, title:e.target.value })} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="reminderDescription">Hatırlatıcı Açıklama</label>
                                        <InputText
                                            placeholder="Lütfen Hatırlatıcı Açıklamasını Giriniz" type="text" id="reminderDescription" value={reminderPostData.description || ''} onChange={(e) => setReminderPostData({...reminderPostData, description:e.target.value })} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="teamName">Hatırlatıcı Tarihi</label>
                                        <Calendar id="time24" value={reminderPostData.startDate} onChange={(e) => setReminderPostData({...reminderPostData, startDate:e.value})} showTime showIcon showSeconds placeholder="Lütfen Hatırlatıcı Tarihi Seçiniz" />
                                    </div>
                                    <div className="field">
                                        <Button label="Hatırlatıcı Ekle" onClick={reminderSave} className="p-button-success mr-2 mb-2" disabled={reminderPostData.title ? false : true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}



export default ReminderAdd;
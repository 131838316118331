import React, { useState, useRef, useEffect } from "react";

// plugins
import { useTranslation } from "react-i18next";

/*Prime Component*/
import { Toast } from "primereact/toast";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { LeadPost } from "../../service/lead";

/*Yetkilendirme ve Rol*/
import { leadEkle } from "../../constants/string";

import { JwtHelper } from "../../plugins/jwtHelper";
import { Chart } from "primereact/chart";
import { Dropdown } from "primereact/dropdown";
import { PersonnelGetList } from "../../service/personnel";

export default function PersonnelIdLeadReport() {
  const { t } = useTranslation();

  const authorityList = JwtHelper.getAuthority(); //Yetkileri getirir
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "ABC PERSONELİ",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [65, 59, 80, 81, 56, 55, 40],
        },
      ],
    };
    const options = {
      indexAxis: "y",
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  const [selectedPersonnel, setSelectedPersonnel] = useState(null);
  const [personnelList, setPersonnelList] = useState({});
  useEffect(() => {
    PersonnelGetList().then((res) => {
      //Personel Listesini Getirelim
      setPersonnelList(res);
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />

          {authorityList.includes(leadEkle) ? (
            <div className="col">
              <div className="card">
                <h5>Personele Göre Lead Durumları</h5>
                <div className="field">
                  <label htmlFor="personnelEMail">Personel Seçimi</label>
                  <Dropdown
                    value={selectedPersonnel}
                    options={personnelList}
                    onChange={(e) => setSelectedPersonnel(e.value)}
                    optionLabel="name"
                    optionValue="id"
                    filter
                    showClear
                    filterBy="name"
                    placeholder={t("global.placeholderSelect")}
                  />
                </div>
                <Chart type="bar" data={chartData} options={chartOptions} />
              </div>
            </div>
          ) : (
            <div className="col">
              <div className="card">
                <h2>Lütfen Yetkili Bir Kullanıcı İle Giriş Yapınız</h2>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

import React, { useState, useRef, useEffect } from 'react';

/*Prime Component*/
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';


/*Component*/
import ContentLoading from '../../components/loading/ContentLoading';


/*Service*/
import { CustomersGetList } from '../../service/customer';


const WhatsappCustomerList = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const toast = useRef(null);
    const [customerData, setCustomerData] = useState([])


    useEffect(() => {
        CustomersGetList(0,"","","","","","").then(res => {
            setIsLoading(false)
            setCustomerData(res.content)
        });
    }, []);
 
    return (
        <>
            {isLoading ? (
                <div>
                    <div className="col-12">
                        <div className="card">
                            <ContentLoading/>
                        </div>
                    </div>
                </div>
            ):(
            <div className='whatsappCustomerListScrool'>
                <Toast ref={toast} />
                {customerData.length > 0 ?(
                    customerData.map((item,i) => {
                        return (
                            <div className='col-12 box-seffaf grid whatsapp-list w-100' key={i} onClick={(e) => console.log("oldu")}>
                                <img src="assets/layout/images/user-default.png" alt={item.name} width="50" className="whatsappTopHeader-img" />
                                <span className='whatsapp-list-content'>
                                    <span className='d-block w-100 bold'>{item.name}</span>
                                    <span className='whatsappTopHeader-lead d-block'>{item.leadState.name}</span>
                                </span>
                                <div className='text-right badge-main'>
                                    <Badge value="4" size="large" severity="success"></Badge>
                                </div>
                            </div>
                        )
                    })
                ):(
                    <h5 style={{marginTop:'30px'}}>Müşteri Bulunamadı</h5>
                )}
            </div>
            )}
        </>
    )
}

// const comparisonFn = function (prevProps, nextProps) {
//     return prevProps.location.pathname === nextProps.location.pathname;
// };

// export default React.memo(Whatsapp, comparisonFn);
export default WhatsappCustomerList

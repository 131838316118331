import { api } from "../constants/string";
import { HTTP } from "../axios/axios";

export function MyDailyReport(id) {
    return new Promise((res, rej) =>
      HTTP.get(`${api}/reports/get-lead-state-pie-chart/${id}`)
        .then((response) => {
          return res(response.data);
        })
        .catch((error) => {
          rej(error);
        })
    );
  }
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// plugins
import moment from "moment";
import { useTranslation } from "react-i18next";

// Prime Component
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { confirmPopup } from "primereact/confirmpopup";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { TeamGetList, TeamDetele } from "../../service/team";
import { TeamUserGetList } from "../../service/user";
import { momentDateFormat } from "../../constants/string";

const List = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [data, setData] = useState([]); //Takım Listesi
  const [teamUserList, setTeamUserList] = useState([
    {
      expanded: true,
      label: "Takım Lideri Yok",
      children: null,
    },
  ]); //Takımda Olan Personeller
  const [displayBasic, setDisplayBasic] = useState(false); //Takımda Olan Personellerin Dialog'u
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    TeamGetList().then((res) => {
      setIsLoading(false);
      setData(res);
    });
    initFilters1();
  }, []);

  //Listelemeye düzenleme ve silme butonunu eklediğimiz kısım
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Link
          className="p-button-rounded p-button-success"
          to={`/takim/${rowData.id}`}
        >
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success mr-2"
          />
        </Link>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger  mr-2"
          tooltip="Takımı Sil"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          onClick={() => {
            confirm2(rowData.id);
          }}
        />
        <Button
          icon="pi pi-user"
          className="p-button-rounded p-button-info  mr-2"
          tooltip="Takım Üyeleri Listesi"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          onClick={() => {
            teamGetUser(rowData.id);
          }}
        />
      </React.Fragment>
    );
  };

  const teamGetUser = (id) => {
    setDisplayBasic(true);
    TeamUserGetList(id).then((res) => {
      setIsLoading(false);
      setTeamUserList(res);
    });
  };

  //Silme İşlemleri Başlar
  const accept = (id) => {
    //Silme işlemi evet diyince
    TeamDetele(id)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  const reject = () => {
    //Silme işlemi hayır diyince
  };

  //Silme Butonu Emin Misiniz Sorusu?
  const confirm2 = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Silme İşlemini Yapmak İstediğinize Emin misiniz?",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => accept(event),
      reject,
    });
  };

  const momentCreatedBodyTemplate = (rowData) => {
    if (rowData.createdDate === null) return "";
    return moment(rowData.createdDate).format(momentDateFormat);
  };

  const momentUpdatedBodyTemplate = (rowData) => {
    if (rowData.updatedDate === null) return "";
    return moment(rowData.updatedDate).format(momentDateFormat);
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };
  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      createdUser: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue1("");
  };
  const clearFilter1 = () => {
    initFilters1();
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label={t("global.tableAramayiTemizle")}
          className="p-button-outlined"
          onClick={clearFilter1}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder={t("global.tableGenelArama")}
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();

  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>{t("takimListesi.takimListesiBaslik")}</h5>
              <p>{t("takimListesi.takimListesiAciklama")}</p>
              <DataTable
                value={data}
                responsiveLayout="scroll"
                paginator
                rows={10}
                header={header1}
                emptyMessage={t("global.tableNoData")}
                filters={filters1}
                filterDisplay="menu"
                dataKey="id"
                globalFilterFields={["name", "createdUser"]}
                rowsPerPageOptions={[
                  10, 20, 30, 40, 50, 100, 150, 200, 250, 300,
                ]}
              >
                <Column field="name" header={t("takimListesi.takimListesiBaslik")}></Column>
                <Column field="createdUser" header={t("takimListesi.takimListesiBaslik")}></Column>
                <Column
                  field={t("takimListesi.takimListesiBaslik")}
                  header="Oluşturma Tarihi"
                  body={momentCreatedBodyTemplate}
                ></Column>
                <Column
                  field="updatedDate"
                  header={t("takimListesi.takimListesiBaslik")}
                  body={momentUpdatedBodyTemplate}
                ></Column>
                <Column
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{ minWidth: "8rem" }}
                ></Column>
              </DataTable>

              {/* Takımda Yer Alan Personeller */}
              <Dialog
                header="Takımda Olan Personeller"
                visible={displayBasic}
                style={{ width: "50vw" }}
                onHide={() => setDisplayBasic(false)}
              >
                <DataTable
                  value={teamUserList}
                  responsiveLayout="scroll"
                  paginator
                  rows={10}
                  emptyMessage="Bu Takıma Ait Personel Bulunamadı"
                >
                  <Column field="name" header="Personel İsmi"></Column>
                  <Column field="role.name" header="Rol"></Column>
                </DataTable>
              </Dialog>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(List, comparisonFn);

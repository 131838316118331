import React, { useState, useRef } from 'react';

/*Prime Component*/
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

/*Component*/
import ContentLoading from '../../components/loading/ContentLoading';



const Index = () => {
    
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);
    const [data, setData] = useState({
        title:'',
        description:'',
        subject:{}
    });

    const save = () => {
        setIsLoading(true)
        // TeamPost(data).then(res => {
        //     setIsLoading(false)
        //     setData({
        //         name:''
        //     });
        //     console.log("cevap geldi=>",res)
        //     toast.current.show({severity:'success', summary: 'Başarılı', detail:'Başarılı Şekilde Takım Eklendi', life: 3000});
        // }).catch(err => {
        //     setIsLoading(false);
        //     console.log("cevap geldi=>", err.response.data)
        //     toast.current.show({severity: 'error', summary: 'Hata', detail: `${err.response.data}`, life: 3000});
        // })
    }


    //subject List
    const subjectList = [
        {name: 'Hata', id: 0},
        {name: 'Yetki Talebi', id: 1},
        {name: 'Yeni Personel', id: 2},
        {name: 'Diğer', id: 3},
    ];

    //Dropdown Search Code Start
    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }
    const countryOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    }
 
    return (
        <>
            {isLoading ? (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <ContentLoading/>
                        </div>
                    </div>
                </div>
            ):(
            <div className="grid p-fluid">
                <Toast ref={toast} />
                <div className="col">
                    <div className="card">
                        <h5>Destek</h5>
                        <p>Aşağıda ki bilgileri doldurarak yeni destek talebi oluşturabilirsiniz.</p>
                        <div className="grid">
                            <div className="col-12 md:col-12">
                                <div className="field">
                                    <label htmlFor="teamName">Destek Adı</label>
                                    <InputText
                                        placeholder="Lütfen Takımın Adını Giriniz" type="text" id="teamName" value={data.title || ''} onChange={(e) => setData({...data, title:e.target.value })} />
                                </div>
                                <div className="field">
                                    <label htmlFor="personnelEMail">Konu Seçiniz</label>
                                    <Dropdown value={data.subject} 
                                        options={subjectList} 
                                        onChange={(e) => setData({...data, subject:e.value })} 
                                        optionLabel="name" 
                                        filter 
                                        showClear 
                                        filterBy="name" 
                                        placeholder="Lütfen Konu Seçiniz"
                                        valueTemplate={selectedCountryTemplate} 
                                        itemTemplate={countryOptionTemplate} />
                                </div>
                                <div className="field">
                                    <label htmlFor="teamCode">Açıklama</label>
                                    <InputTextarea className='mt-2' placeholder="Lütfen Açıklama Giriniz" value={data.description} onChange={(e) => setData({...data, description:e.target.value })} rows={5} cols={30} />
                                </div>
                            </div>
                            <div className="col-12 md:col-4"></div>
                            <div className="col-12 md:col-4"></div>
                            <div className="col-12 md:col-4">
                                <Button label="Gönder" onClick={save} className="p-button-success mr-2 mb-2" disabled={data.name ? false : true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Index, comparisonFn);

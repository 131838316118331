import { api, momentURLFormat } from "../constants/string";
import { HTTP } from "../axios/axios";
import moment from "moment";

export function CustomersPost(data) {
  return new Promise((res, rej) =>
    HTTP.post(`${api}/customers`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function CustomersPut(data) {
  return new Promise((res, rej) =>
    HTTP.put(`${api}/customers`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function CustomersGet(id) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/customers/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function CustomersGetList(
  page,
  name,
  surname,
  phone,
  leadStateId,
  source,
  personnel,
  size,
  metaId,
  dateData,
  isCalled,
  sortUpdateDate,
  getLeadChangeAndNote
) {
  const getLeadStateButNoteFound = `&getLeadChangeAndNote=${
    getLeadChangeAndNote ? "true" : "false"
  }`;
  if (dateData.createdDateEnd) {
    return new Promise((res, rej) =>
      HTTP.get(
        `${api}/customers?page=${page}&createdDateStart=${moment(
          dateData.createdDateStart
        ).format(momentURLFormat)}&createdDateEnd=${moment(
          dateData.createdDateEnd
        ).format(
          momentURLFormat
        )}&name=${name}&surname=${surname}&phoneNumber=${phone}&leadStateId=${leadStateId}&userId=${personnel}&source=${source}&metaId=${metaId}${getLeadStateButNoteFound}&size=${size}&sort=id,desc`
      )
        .then((response) => {
          return res(response.data);
        })
        .catch((error) => {
          rej(error);
        })
    );
  } else if (dateData.updatedDateEnd) {
    return new Promise((res, rej) =>
      HTTP.get(
        `${api}/customers?page=${page}&updatedDateStart=${moment(
          dateData.updatedDateStart
        ).format(momentURLFormat)}&updatedDateEnd=${moment(
          dateData.updatedDateEnd
        ).format(
          momentURLFormat
        )}&name=${name}&surname=${surname}&phoneNumber=${phone}&leadStateId=${leadStateId}&userId=${personnel}&source=${source}&metaId=${metaId}${getLeadStateButNoteFound}&size=${size}&sort=id,desc`
      )
        .then((response) => {
          return res(response.data);
        })
        .catch((error) => {
          rej(error);
        })
    );
  } else {
    return new Promise((res, rej) => {
      const updatedDate =
        sortUpdateDate !== undefined
          ? `&sort=updatedDate,${sortUpdateDate}`
          : "";
      HTTP.get(
        `${api}/customers?page=${page}&name=${name}&surname=${surname}&phoneNumber=${phone}&isCalled=${isCalled}&leadStateId=${leadStateId}&userId=${personnel}&source=${source}&metaId=${metaId}${getLeadStateButNoteFound}&size=${size}&sort=id,desc${updatedDate}`
      )
        .then((response) => {
          return res(response.data);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }
}

export function CustomersGetFindAllList(
  name,
  surname,
  phone,
  leadStateId,
  source,
  personnel
) {
  //Tüm müşteri listesini getirir sayfalama olmadan
  return new Promise((res, rej) =>
    HTTP.get(
      `${api}/customers/findAll?name=${name}&surname=${surname}&phoneNumber=${phone}&leadStateId=${leadStateId}&userId=${personnel}&source=${source}`
    )
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function CustomersImportPost(data) {
  const formData = new FormData();
  formData.append("file", data);
  return new Promise((res, rej) =>
    HTTP.post(`${api}/customers/import`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function CustomerCreateAll(data, isAds) {
  if (isAds === undefined || isAds === null) {
    isAds = false;
  }
  return new Promise((res, rej) =>
    HTTP.post(`${api}/customers/createAll?isAds=${isAds}`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function CustomerAssignmentGet(id) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/customer-assignment/customer/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function CustomersImportFirstTimePost(data) {
  const formData = new FormData();
  formData.append("file", data);
  return new Promise((res, rej) =>
    HTTP.post(`${api}/customers/import/first-time`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function CustomersUpdateAll(data) {
  return new Promise((res, rej) =>
    HTTP.put(`${api}/customers/update-all`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function CustomersgoogleSheetsGet() {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/customer-unassignment/`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function CustomerUnassignmentGetList(type, page, size) {
  return new Promise((res, rej) =>
    HTTP.get(
      `${api}/customer-unassignment/filter?page=${page}&type=${type}&size=${size}&sort=id,desc`
    )
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function CustomersDetele(id) {
  return new Promise((res, rej) =>
    HTTP.delete(`${api}/customers/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function CustomersPhoneGet(phone) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/customers/phone-number/${phone}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function CustomerInfoAggregationLimited() {
  return new Promise((resolve, reject) =>
    HTTP.get(`${api}/customers-info/limit`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => reject(error))
  );
}
export function CustomerInfoAggregation() {
  return new Promise((resolve, reject) =>
    HTTP.get(`${api}/customers-info`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => reject(error))
  );
}

export function CustomerInfoAggregationExcel() {
  return new Promise((resolve, reject) =>
    HTTP.post(`${api}/customers-info/to-excel`, null, { responseType: "blob" })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error))
  );
}

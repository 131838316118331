import React, {useState, useEffect, useRef} from "react";
import {Mt4NewBalance, Mt4NewHistories, Mt5NewBalance, Mt5NewHistories} from "../../service/mt4Service";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useParams} from "react-router-dom";
import {Knob} from "primereact/knob";
import {JwtHelper} from "../../plugins/jwtHelper";

const Detail = () => {

    const [histories, setHistories] = useState([]);
    const [balance, setBalance] = useState({in: 0, out: 0, open: 0, total: 0});
    const {id} = useParams();

    useEffect(() => {

        if (JwtHelper.isMt4Enable()) {
            Mt4NewHistories(id)
                .then(data => {
                    setHistories(data);
                });

            Mt4NewBalance(id)
                .then(data => {
                    setBalance(data);
                });
        } else if (JwtHelper.isMt5Enable()) {
            Mt5NewHistories(id)
                .then(data => {
                    setHistories(data);
                });

            Mt5NewBalance(id)
                .then(data => {
                    setBalance(data);
                });
        }
    }, [])

    return (
        <div className="card">
            <div className="grid p-fluid">
                <div className="col-3">
                    {balanceKnob(balance.in, "green", "in")}
                </div>
                <div className="col-3">
                    {balanceKnob(balance.out, "red", "out")}
                </div>
                <div className="col-3">
                    {balanceKnob(balance.open, "blue", "open")}
                </div>
                <div className="col-3">
                    {balanceKnob(balance.total, "yellow", "total")}
                </div>
            </div>
            <div className="grid p-fluid">
                <div className="col-12">
                    <h5>History</h5>
                    {historyList(histories)}
                </div>
            </div>
        </div>
    )
}

const balanceKnob = (value, color, typeText) => {

    return (
        <div className="col-12">
            <Knob value={value} disabled={true} valueColor={color} size={200} max={value < 0 ? -10000 : 10000}/>
            <div className="h5">{typeText}</div>
        </div>
    )
}
const historyList = (histories) => {
    return (
        <DataTable value={histories}>
            <Column field="order" header="Order"></Column>
            <Column field="login" header="Login"></Column>
            <Column field="symbol" header="Symbol"></Column>
            <Column field="profit" header="Profit"></Column>
        </DataTable>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Detail, comparisonFn);
import axios from "axios";

const config = {};

export const HTTP = axios.create(config);

HTTP.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
});

HTTP.interceptors.response.use((response) => {
    if (response.status === 401) {
        //todo : burada login ekranına yönlendirilmeli.
        localStorage.setItem("token", "");
        setTimeout(() => {
            window.location.reload();
            window.location.hash = "/giris"
        }, 1000);
    } else {
        return Promise.resolve(response)
    }
}, error => Promise.reject(error));

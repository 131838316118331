import React, { useState, useRef, useEffect } from "react";

// plugins
import { useTranslation } from "react-i18next";

/*Prime Component*/
import { Toast } from "primereact/toast";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { LeadPost } from "../../service/lead";

/*Yetkilendirme ve Rol*/
import { leadEkle } from "../../constants/string";

import { JwtHelper } from "../../plugins/jwtHelper";
import { Chart } from "primereact/chart";

export default function AdvertisementPieChart() {
  const { t } = useTranslation();

  const authorityList = JwtHelper.getAuthority(); //Yetkileri getirir
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      datasets: [
        {
          data: [11, 16, 7, 3, 14],
          backgroundColor: [
            documentStyle.getPropertyValue("--red-500"),
            documentStyle.getPropertyValue("--green-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--bluegray-500"),
            documentStyle.getPropertyValue("--blue-500"),
          ],
          label: "My dataset",
        },
      ],
      labels: ["Red", "Green", "Yellow", "Grey", "Blue"],
    };
    const options = {
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        r: {
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />

          {authorityList.includes(leadEkle) ? (
            <div className="col">
              <div className="card">
                <h5>Reklam Datalarına Göre Lead Durumları</h5>
                <Chart
                  type="polarArea"
                  data={chartData}
                  options={chartOptions}
                  style={{ position: "relative", width: "40%" }}
                />
              </div>
            </div>
          ) : (
            <div className="col">
              <div className="card">
                <h2>Lütfen Yetkili Bir Kullanıcı İle Giriş Yapınız</h2>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

import {api} from '../constants/string';
import {HTTP} from "../axios/axios";

export function ReminderCustomerPost(data) {

    return new Promise((res, rej) =>
        HTTP.post(`${api}/schedules`, data)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}

export function ReminderCustomerGet(id) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/schedules/by-customer`, {id})
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}

export function ReminderUserGet(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/notifications/by-user/${id}/not-reading`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}

export function ReminderUserSetReadingTrue(id, state) {
    return new Promise((resolve, reject) => {
        HTTP.put(`${api}/notifications/set-reading-true/${id}/${state}`)
            .then(response => {
                return resolve(response.data);
            })
            .catch((error) => {
                 
                reject(error);
            })
    })
}
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';

/*Prime Component*/
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';


// plugins
import moment from 'moment';
import Picker from 'emoji-picker-react';
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import ReactAudioPlayer from "react-audio-player";


/*Component*/
import ContentLoading from '../../components/loading/ContentLoading';


/*Service*/
import { WhatsappSendCustomerMessage, WhatsappCustomerGetMessage, WhatsappSendCustomerImage, WhatsappSendCustomerFile, WhatsappSendCustomerVoice } from '../../service/whatsapp';

import {
    momentTimeFormat,
} from '../../constants/string';


const Whatsapp = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);
    const [dataMessage, setDataMessage] = useState({
        message:'',
        to:props.customerData.phoneNumberList[0].phoneNumber,
        customerName:props.customerData.name
        // userId:JwtHelper.getUserId(),
        // customerId:props.customerData.id
    });
    const [dataChatGet, setDataChatGet] = useState(props.customerData.phoneNumberList[0].phoneNumber);
    const [dataChatListMesaage, setDataChatListMesaage] = useState([]);
    const [dataImg, setDataImg] = useState({
        caption:"",
        to:props.customerData.phoneNumberList[0].phoneNumber,
        customerName:props.customerData.name
        // userId:JwtHelper.getUserId(),
        // customerId:props.customerData.id
    })


    useEffect(() => {
        WhatsappCustomerGetMessage(dataChatGet).then(res => {
            setIsLoading(false)
            setDataChatListMesaage(res)
        });
        setInterval(() => {
            WhatsappCustomerGetMessage(dataChatGet).then(res => {
                setIsLoading(false)
                setDataChatListMesaage(res)
            }).catch(err => {
                setIsLoading(false);
                
                toast.current.show({severity: 'error', summary: 'Hata', detail: `${err.response.data}`, life: 3000});
            });
        }, 3000);
    }, []);


    const save = () => { //Gönder butonuna basınca mesajı gönderir
        setIsLoading(true)
        WhatsappSendCustomerMessage(dataMessage).then(res => {
            setIsLoading(false)
            
            setDataMessage({...dataMessage, message:"" });
            toast.current.show({severity:'success', summary: 'Başarılı', detail:'Başarılı Şekilde Mesaj Gönderildi', life: 3000});
        }).catch(err => {
            setIsLoading(false);
            
            toast.current.show({severity: 'error', summary: 'Hata', detail: `${err.response.data}`, life: 3000});
        })
    }    
    
    const onKeyUpValue = (event) => { //Enter tuşuna basılınca mesajı gönderir
        if(event.keyCode === 13){
            save()
        }
    }

    //Görsel Gönderimi Başlar
    const ImageUpload = (e) => { //Görsel Gönderimi Yapar
        setIsLoading(true)
        WhatsappSendCustomerImage(dataImg, e.target.files[0]).then(res => {
            setIsLoading(false)
            toast.current.show({severity:'success', summary: 'Başarılı', detail:'Başarılı Şekilde Görsel Gönderildi', life: 3000});
        }).catch(err => {
            setIsLoading(false);
            
            toast.current.show({severity: 'error', summary: 'Hata', detail: `${err.response.data}`, life: 3000});
        })
    }
    const ImageInputRef = useRef(null);
    //Görsel Gönderimi Biter

    //Döküman Gönderimi Başlar
    const FileUpload = (e) => { //Görsel Gönderimi Yapar
        setIsLoading(true)
        WhatsappSendCustomerFile(dataImg, e.target.files[0]).then(res => {
            setIsLoading(false)
            toast.current.show({severity:'success', summary: 'Başarılı', detail:'Başarılı Şekilde Döküman Gönderildi', life: 3000});
        }).catch(err => {
            setIsLoading(false);
            
            toast.current.show({severity: 'error', summary: 'Hata', detail: `${err.response.data}`, life: 3000});
        })
    }
    const FileInputRef = useRef(null);
    //Döküman Gönderimi Biter

    //Ses Gönderimi Başlar
    const VoiceUpload = () => { //Görsel Gönderimi Yapar
        setIsLoading(true)
        WhatsappSendCustomerVoice(blobURL,props.customerData.phoneNumberList[0].phoneNumber).then(res => {
            setRecordSoundShow(false)
            setIsLoading(false)
            toast.current.show({severity:'success', summary: 'Başarılı', detail:'Başarılı Şekilde Ses Gönderildi', life: 3000});
        }).catch(err => {
            setIsLoading(false);
            
            toast.current.show({severity: 'error', summary: 'Hata', detail: `${err.response.data}`, life: 3000});
        })
    }
    const VoiceInputRef = useRef(null);
    //Ses Gönderimi Biter
    

    //Ses olayları Buradan Başlar
    const [recordstate, setrecordstate] = useState("NONE");
    const [blobURL, setblobURL] = useState({});
    const [recordGraphicShow, setRecordGraphicShow] = useState(false) //Ses kaydı yapılırken görsel alanı
    const [recordSoundShow, setRecordSoundShow] = useState(false) //Ses dinleme alanı
    const startRecord = () => {
      setrecordstate(RecordState.START);
      setRecordGraphicShow(true)
    };
  
    const stopRecord = () => {
      setrecordstate(RecordState.STOP);
      setRecordGraphicShow(false)
      setRecordSoundShow(true)
    };
  
    const onStopRecord = (audioData) => {
      setblobURL(audioData);
    };

    const recordingSoundRemove = () => { //Ses dosyasını müşteriye göndermeden sil
        setRecordSoundShow(false)
    }
    //Ses olayları Buradan Biter

    //Emoji Kısımları Başlar
    const onEmojiClick = (event, emojiObject) => {
        dataMessage.message += emojiObject.emoji;
        setChosenEmojiShow(false)
    };
    const [chosenEmojiShow, setChosenEmojiShow] = useState(false);
    //Emoji Kısımları Biter

    return (
        <>
            <div className="grid p-fluid ">
                <Toast ref={toast} />
                <div className="col whatsappBody p-0">
                    <div className='card whatsappTopHeader w-100 bRadius-0 pt-3 pb-0'>
                        <img src="assets/layout/images/user-default.png" alt="user" width="50" className="whatsappTopHeader-img" />
                        <span className='whatsappTopHeader-content'>
                            <span className='whatsappTopHeader-title bold'>{props.customerData.name}</span>
                            <span className='whatsappTopHeader-lead d-block'>{props.customerData.leadState.name}</span>
                        </span>
                    </div>
                    <div className="grid">
                        <div className='col-12 whatsappContentScroll'>
                            {dataChatListMesaage.length > 0 ?(
                                dataChatListMesaage.map((item,i) => {
                                    return (
                                        <div className="col-12 md:col-12" key={i}>
                                            <span className={"d-block w-100 " + (item.data.fromMe ? 'text-right ' : 'text-left')}>
                                                {item.data.type == "image" ? ( //Eğer içeriğin tipi image ise bunu göster
                                                    <a href={item.data.media} target="_blank">
                                                        <Image width='100' height='100' src={`${item.data.media}`} /> 
                                                    </a>
                                                ):item.data.type == "document" ?(//Eğer içeriğinde tipi document ise bunu göster
                                                    <a className='whatsappFileMain mb-3' href={item.data.media}>
                                                        <span>{item.data.body}</span>
                                                        <i className="pi pi-download ml-1 mr-1"></i>
                                                        <span className='whatsappTextBoxTime'>{moment(item.data.timestamp).format(momentTimeFormat)}</span>
                                                    </a>
                                                ):item.data.type == "ptt" ?(
                                                    <div className='whatsappSoundMain'>
                                                        <ReactAudioPlayer src={item.data.media} controls /> 
                                                        <span className='whatsappTextBoxTime'>{moment(item.data.timestamp).format(momentTimeFormat)}</span>
                                                    </div>
                                                ):(
                                                    <p className={(item.data.fromMe ? 'whatsappTextBoxMe': 'whatsappTextBoxTo')}>
                                                        {item.data.body}
                                                        <span className='whatsappTextBoxTime'>{moment(item.data.timestamp).format(momentTimeFormat)}</span>
                                                    </p>
                                                )}
                                            </span>
                                        </div>
                                    )
                                })
                            ):(
                                <h5 style={{marginTop:'30px'}}></h5>
                            )}
                            <div id="yourDivID"></div>
                        </div>
                    </div>
                    <div className='card mb-0 bRadius-0 pr-0 pl-0 position-relative'>
                        {isLoading ? (
                            <div className="grid w-100">
                                <div className="col-12">
                                    <div className="card">
                                        <ContentLoading/>
                                    </div>
                                </div>
                            </div>
                        ):(
                            <div className="d-block w-100 whatsappFooter">
                                <Button icon="pi pi-palette" //Emoji pencerisini açar
                                    onClick={(e)=> setChosenEmojiShow(true)}
                                    tooltip="Emoji Gönder" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                                    className="p-button-success mr-0 mb-2 whatsappSendButton" 
                                />
                                {chosenEmojiShow && (//Emoji Kısmı
                                    <Picker onEmojiClick={onEmojiClick}
                                    //   groupNames={{
                                    //     smileys_people: 'yellow faces',
                                    //     animals_nature: 'cute dogs and also trees',
                                    //     food_drink: 'milkshakes and more',
                                    //     travel_places: 'I love trains',
                                    //     activities: 'lets play a game',
                                    //     objects: 'stuff',
                                    //     symbols: 'more stuff',
                                    //     flags: 'fun with flags',
                                    //     recently_used: 'did I really use those?!',
                                    //   }}
                                    />
                                )}

                                <Button icon="pi pi-image" //Görsel Gönderir
                                    onClick={(e)=> ImageInputRef.current.click()}
                                    tooltip="Görsel Gönder" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                                    className="p-button-info mr-0 mb-2 whatsappSendButton" 
                                />
                                <input style={{display: 'none'}} //Görsel File Upload
                                    ref={ImageInputRef} type="file" name="file" className="form-control" value="" onChange={(e)=> ImageUpload(e)} placeholder="" />
                                <Button icon="pi pi-file" //Dosya Gönderir
                                    onClick={(e)=> FileInputRef.current.click()}
                                    tooltip="Dosya Gönder" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} 
                                    className="p-button-success mr-0 mb-2 whatsappSendButton" 
                                />
                                <input style={{display: 'none'}} //Görsel File Upload
                                    ref={FileInputRef} type="file" name="file" className="form-control" value="" onChange={(e)=> FileUpload(e)} placeholder="" />
                                
                                <div className={"whatsappTextMain " + (recordGraphicShow ? 'show' : 'hidden')}>
                                    <AudioReactRecorder state={recordstate} onStop={onStopRecord} />

                                </div>
                                <div className={"whatsappTextMain " + (recordGraphicShow  ? 'hidden' : 'show')}>
                                    <InputTextarea //Mesaj girilen alan
                                            placeholder="Lütfen Mesajınızı Giriniz" type="text" className='whatsappTextArea' id="whatsappMessage" value={dataMessage.message || ''} 
                                            onChange={(e) => setDataMessage({...dataMessage, message:e.target.value })} rows={1} cols={30} 
                                            onKeyUp={onKeyUpValue.bind(this)}
                                        />
                                </div>

                                <Button icon="pi pi-volume-up" //Ses Kaydetme Başlatma Butonu
                                    onClick={startRecord} 
                                    tooltip="Ses Kaydet" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                                    className={"p-button-info mb-2 whatsappSendButton " + (recordGraphicShow  ? 'hidden' : 'show')}
                                />
                                <Button icon="pi pi-volume-off" //Ses Kaydetme Bitirme Butonu
                                    onClick={stopRecord} 
                                    tooltip="Ses Kaydını Bitir" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                                    className={"p-button-danger mb-2 whatsappSendButton " + (recordGraphicShow  ? 'show' : 'hidden')}
                                />

                                <Button icon="pi pi-send" //Mesaj Gönderme Butonu
                                    onClick={save} 
                                    tooltip="Mesajı Gönder" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                                    className="p-button-success mr-2 mb-2 whatsappSendButton" 
                                    disabled={dataMessage.message ? false : true} 
                                />
                                <div className={"w-100 record-sound-main " + (recordSoundShow  ? 'show' : 'hidden')}>
                                    {/* Kaydedilen ses kaydını dinle */}
                                    <ReactAudioPlayer src={blobURL?.url} controls /> 

                                    <Button icon="pi pi-send" //Ses Kaydını Gönderme Butonu
                                        onClick={VoiceUpload} 
                                        tooltip="Ses Kaydını Gönder" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                                        className="p-button-success mr-2 mb-2"
                                        label="Ses Kaydını Gönder" 
                                    />
                                    <Button icon="pi pi-send" //Ses Kaydını Gönderme Butonu
                                        onClick={recordingSoundRemove} 
                                        tooltip="Ses Kaydını Sil" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                                        className="p-button-danger mr-2 mb-2"
                                        label="Ses Kaydını Sil" 
                                    />
                                </div>
                            </div>          
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

// const comparisonFn = function (prevProps, nextProps) {
//     return prevProps.location.pathname === nextProps.location.pathname;
// };

// export default React.memo(Whatsapp, comparisonFn);
export default Whatsapp

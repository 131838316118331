import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

// plugins
import { useTranslation } from "react-i18next";

/*Prime Component*/
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import {
  TicketGet,
  TicketBySupportPost,
  TicketBySupport,
} from "../../service/ticket";

const Detail = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [data, setData] = useState();
  const [sendData, setSendData] = useState({
    content: "",
    support: {
      id: "",
    },
  });
  const [ticketModelList, setTicketModelList] = useState([]);
  const location = useLocation();
  const [pageId] = useState(location.pathname.split("/")[2]);

  const ticketGetList = () => {
    TicketGet(pageId).then((res) => {
      setIsLoading(false);
      setData(res);
    });
  };
  const ticketBySupportGetList = () => {
    TicketBySupport(pageId).then((res) => {
      setTicketModelList(res);
      console.log("geldi", res);
    });
  };

  useEffect(() => {
    ticketGetList();
    ticketBySupportGetList();
  }, [pageId]);

  const save = () => {
    setIsLoading(true);
    sendData.support.id = pageId;
    TicketBySupportPost(sendData)
      .then((res) => {
        setIsLoading(false);

        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Cevap Gönderildi.",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />
          <div className="col">
            <div className="card">
              <h5>{data?.title} başlıklı ticket</h5>
              <p>
                {data?.title} başlıklı ticket detaylarını aşağıda
                görebilirsiniz.
              </p>
              <div>
                Öncelik Durumu:
                {data?.importanceLevel === "LOW"
                  ? "Düşük"
                  : data?.importanceLevel === "MEDIUM"
                  ? "Orta"
                  : "Yüksek"}
              </div>
              <div>
                Durumu:{" "}
                {data?.status === "IN_PROGRESS"
                  ? "İşleme Alındı"
                  : data?.status === "OPEN"
                  ? "Açık"
                  : "Tamamlandı"}
              </div>
              <div className="grid mt-5">
                <div className="col-12 md:col-12 mt-5">
                  <div className="chat-container">
                    <ul className="chat">
                      {/* Bu kısım ticket'e yapılan tüm detayları render eder. */}
                      {ticketModelList?.map((item, i) => {
                        if (item.isUser) {
                          return (
                            <li className="message right" key={i}>
                              <div className="flex flex-column align-items-start">
                                <p className="w-100 name">
                                  {item?.createdUser}
                                </p>
                                <p>{item?.content}</p>
                                <div className="datetime flex flex-column mt-3">
                                  <small>{item?.createdDate}</small>
                                </div>
                              </div>
                              <img
                                className="logo"
                                src="/images/avatar.png"
                                alt="avatar"
                              />
                            </li>
                          );
                        } else {
                          return (
                            <li className="message left" key={i}>
                              <img
                                className="logo"
                                src="/images/avatar.png"
                                alt="avatar"
                              />
                              <div className="flex flex-column align-items-start">
                                <p className="w-100 name">
                                  {item?.createdUser}
                                </p>
                                <p>{item?.content}</p>
                                <div className="datetime flex flex-column mt-3">
                                  <small>{item?.createdDate}</small>
                                </div>
                              </div>
                            </li>
                          );
                        }
                      })}
                      {/* <li className="message left">
                        <img
                          className="logo"
                          src="/images/avatar.png"
                          alt="avatar"
                        />
                        <div className="flex flex-column align-items-end">
                          <p className="w-100 name">Test Kullanıcı</p>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                          </p>
                          <div className="datetime flex flex-column mt-3">
                            <small>11/11/2023</small>
                            <small>12:00</small>
                          </div>
                        </div>
                      </li>
                      <li className="message right">
                        <div className="flex flex-column align-items-start">
                          <p className="w-100 name">Test Kullanıcı</p>
                          <img
                            className="file-appearance"
                            src="/images/pdf.jpg"
                            alt="avatar"
                          />
                          <p>Dosya gönderimi</p>
                          <div className="datetime flex flex-column mt-3">
                            <small>11/11/2023</small>
                            <small>12:00</small>
                          </div>
                        </div>
                        <img
                          className="logo"
                          src="/images/avatar.png"
                          alt="avatar"
                        />
                      </li> */}

                      {/* Bu kısım ilk detay kısmını render eder. Ticket'i kim oluşturduysa onun içerikleri */}
                      <li className="message right">
                        <div className="flex flex-column align-items-start">
                          <p className="w-100 name">{data?.createdUser}</p>
                          <p>{data?.content}</p>
                          <div className="datetime flex flex-column mt-3">
                            <small>{data?.createdDate}</small>
                          </div>
                        </div>
                        <img
                          className="logo"
                          src="/images/avatar.png"
                          alt="avatar"
                        />
                      </li>
                    </ul>
                    <div className="input-area">
                      <input
                        type="text"
                        className="text_input"
                        placeholder="Mesaj.."
                        value={sendData.content}
                        onChange={(e) =>
                          setSendData({ ...sendData, content: e.target.value })
                        }
                      />
                      <div className="send-btn">
                        {/* <input
                          type="file"
                          name="file"
                          id="file"
                          className="input-file"
                        />
                        <label
                          for="file"
                          className="btn btn-tertiary js-labelFile img-btn"
                        >
                          <i className="pi pi-fw pi-images"></i>
                        </label> */}
                        <Button onClick={save}>
                          <i className="pi pi-fw pi-send"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Detail, comparisonFn);

import { api } from "../constants/string";
import { HTTP } from "../axios/axios";

export function CallHistoriesPost(id) {
  return new Promise((res, rej) =>
    HTTP.post(`${api}/call-histories/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

import {api} from '../constants/string';
import {HTTP} from "../axios/axios";

export function LeadPost(data) {

    return new Promise((res, rej) =>
        HTTP.post(`${api}/lead-states`, data)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}
export function LeadPut(data) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/lead-states`, data)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}

export function LeadGet(id) {

    return new Promise((res, rej) =>
        HTTP.get(`${api}/lead-states/${id}`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}

export function LeadGetList() {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/lead-states`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}

export function LeadDetele(id) {

    return new Promise((res, rej) =>
        HTTP.delete(`${api}/lead-states/${id}`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
}
import {api} from '../constants/string';
import {HTTP} from "../axios/axios";


export function ThemeSettingsPost(data) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/theme-settings`, data)
        .then(response => {
            return res(response.data);
        })
        .catch((error) => {
             
            rej(error);
        }),
    );
}

export function ThemeSettingsPut(data) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/theme-settings`, data)
        .then(response => {
            return res(response.data);
        })
        .catch((error) => {
             
            rej(error);
        }),
);
}

export function ThemeSettingsGet(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/theme-settings/user/${id}`)
        .then(response => {
            return res(response.data);
        })
        .catch((error) => {
             
            rej(error);
        }),
    );
}
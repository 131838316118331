import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// plugins
import moment from "moment";
import { useTranslation } from "react-i18next";

// Prime Component
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { CustomerStatusAllList } from "../../service/log";
import { momentDateFormat } from "../../constants/string";

const List = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  //Listelemeye düzenleme ve silme butonunu eklediğimiz kısım
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Link
          className="p-button-rounded p-button-success mr-2"
          to={`/musteri/${rowData.id}`}
        >
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success mr-2"
          />
        </Link>
      </React.Fragment>
    );
  };

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [lazyParams, setLazyParams] = useState({
    first: 1,
    rows: 5,
    page: 0,
  });

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      CustomerStatusAllList(lazyParams.page).then((data) => {
        setTotalRecords(data.totalElements);
        setCustomers(data.content);
        setLoading(false);
        setIsLoading(false);
      });
    }, Math.random() * 1000 + 250);
  };

  const onPage = (event) => {
    setLazyParams(event);
  };

  const momentBodyTemplate = (rowData) => {
    return moment(rowData.createdDate).format(momentDateFormat);
  };

  const customerPhoneBodyTemplate = (rowData) => {
    //Listeleme Telefon gösterimi
    return rowData.customer.phoneNumberList.map((item, i) => {
      return (
        <span key={i} style={{ display: "block", marginBottom: 15 }}>
          <span style={{ lineHeight: "40px" }}>{item.phoneNumber}</span>
        </span>
      );
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="col">
          <div className="card">
            <div className="grid">
              <div className="col-12">
                <h5>{t("anasayfa.tumMusteriSorumluKisiGecmisiBaslik")}</h5>
                <p>{t("anasayfa.tumMusteriSorumluKisiGecmisiAciklama")}</p>
                <DataTable
                  value={customers}
                  lazy
                  responsiveLayout="scroll"
                  dataKey="id"
                  paginator
                  first={lazyParams.first}
                  rows={10}
                  totalRecords={totalRecords}
                  onPage={onPage}
                  loading={loading}
                >
                  <Column
                    field="customer.id"
                    header={t("anasayfa.tumMusteriSorumluKisiGecmisiTabloMusteriId")}
                    filterPlaceholder={t("global.tablePlaceholderSearchText")}
                  />
                  <Column
                    field="customer.name"
                    header={t("anasayfa.tumMusteriSorumluKisiGecmisiTabloMusteriAdi")}
                    filterPlaceholder={t("global.tablePlaceholderSearchText")}
                  />
                  <Column
                    field="customer.surname"
                    header={t("anasayfa.tumMusteriSorumluKisiGecmisiTabloMusteriSoyadi")}
                    filterPlaceholder={t("global.tablePlaceholderSearchText")}
                  />
                  <Column
                    field="phone"
                    body={customerPhoneBodyTemplate}
                    header={t("anasayfa.tumMusteriSorumluKisiGecmisiTabloMusteriTelefon")}
                    filterPlaceholder={t("global.tablePlaceholderSearchText")}
                    style={{ minWidth: "9rem" }}
                  ></Column>
                  <Column
                    field="user.name"
                    header={t("anasayfa.tumMusteriSorumluKisiGecmisiTabloSuanSorumlu")}
                    filterPlaceholder={t("global.tablePlaceholderSearchText")}
                  />
                  <Column
                    field="createdDate"
                    header={t("anasayfa.tumMusteriSorumluKisiGecmisiTabloDegisiklikTarihi")}
                    body={momentBodyTemplate}
                    filterPlaceholder={t("global.tablePlaceholderSearchText")}
                  />
                  <Column
                    body={actionBodyTemplate}
                    exportable={false}
                    style={{ minWidth: "8rem" }}
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(List, comparisonFn);

import {api} from '../constants/string';
import {HTTP} from "../axios/axios";

export function ActivitiesGet(page,userId) {
  return new Promise((res, rej) =>
      HTTP.get(`${api}/activities?page=${page}&userId=${userId}&sort=id,desc`)
          .then(response => {
              return res(response.data);
          })
          .catch((error) => {
               
              rej(error);
          }),
  );
}
export function CustomerStatusAllList(page) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/customer-assignment?page=${page}&sort=id,desc`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
  }
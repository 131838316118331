import React, { useState, useEffect } from "react";

// plugins
import moment from "moment";
import { useTranslation } from "react-i18next";

// Prime Component
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Filter */
import { updateLocaleOptions } from "primereact/api";

/*Service*/
import { PersonnelGetList } from "../../service/personnel";
import { ActivitiesGet } from "../../service/log";
import { momentDateFormat } from "../../constants/string";

const List = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [personnelList, setPersonnelList] = useState({});
  const [personnelData, setPersonnelData] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 1,
    rows: 5,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" },
      surname: { value: "", matchMode: "contains" },
    },
  });

  let loadLazyTimeout = null;

  useEffect(() => {
    PersonnelGetList().then((res) => {
      //Personel Listesini Getirelim
      setPersonnelList(res);
    });
    loadLazyData();
    updateLocaleOptions({
      startsWith: "İle Başlar",
      contains: "İçerir",
      notContains: "İçermez",
      endsWith: "İle Biter",
      equals: "Eşittir",
      notEquals: "Eşit Değildir",
      noFilter: "Filtreyi Kaldır",
      lt: "Daha Az",
      lte: "Küçük veya Eşit",
      gt: "Daha Büyük",
      gte: "Büyük veya Eşit",
      dateIs: "Tarih",
      dateIsNot: "Tarih Değil",
      dateBefore: "Tarih Öncesi",
      dateAfter: "Tarih Sonra",
      custom: "Özel",
      clear: "Temizle",
      apply: "Onayla",
      matchAll: "Tümünü Eşleştir",
    });
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      ActivitiesGet(lazyParams.page, personnelData).then((data) => {
        setTotalRecords(data.totalElements);
        setCustomers(data.content);
        setLoading(false);
        setIsLoading(false);
      });
    }, Math.random() * 1000 + 250);
  };

  const onPage = (event) => {
    setLazyParams(event);
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const onFilter = (event) => {
    event["first"] = 0;
    setLazyParams(event);
  };

  const onSelectionChange = (event) => {
    const value = event.value;
    setSelectedCustomers(value);
    setSelectAll(value.length === totalRecords);
  };

  const onSelectAllChange = (event) => {
    const selectAll = event.checked;

    if (selectAll) {
      ActivitiesGet().then((data) => {
        setSelectAll(true);
        setSelectedCustomers(data.customers);
      });
    } else {
      setSelectAll(false);
      setSelectedCustomers([]);
    }
  };

  //Dropdown Search Code Start
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.name}</div>
      </div>
    );
  };

  //Personel Seçilince Tabloyu günceller
  const logOnClick = (id) => {
    setLoading(true);
    ActivitiesGet(lazyParams.page, id).then((data) => {
      setTotalRecords(data.totalElements);
      setCustomers(data.content);
      setLoading(false);
      setIsLoading(false);
    });
  };

  const momentBodyTemplate = (rowData) => {
    return moment(rowData.createdDate).format(momentDateFormat);
  };

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="p-fluid">
          <div className="col">
            <div className="card">
              <div className="grid">
                <div className="col-12">
                  <h5>{t("anasayfa.tumHareketGemicisiBaslik")}</h5>
                  <p>{t("anasayfa.tumHareketGecmisiAciklama")}</p>
                  <div className="field">
                    <label htmlFor="personnelEMail">{t("anasayfa.tumMusteriNotlariPersonel")}</label>
                    <Dropdown
                      value={personnelData}
                      options={personnelList}
                      onChange={(e) => {
                        logOnClick(e.value);
                        setPersonnelData(e.value);
                      }}
                      optionLabel="name"
                      optionValue="id"
                      filter
                      showClear
                      filterBy="name"
                      placeholder={t("global.placeholderSelect")}
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                    />
                  </div>
                  <DataTable
                    value={customers}
                    lazy
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    dataKey="id"
                    emptyMessage={t("anasayfa.tumHareketGemicisiBulunamadi")}
                    paginator
                    first={lazyParams.first}
                    rows={10}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    onSort={onSort}
                    sortField={lazyParams.sortField}
                    sortOrder={lazyParams.sortOrder}
                    onFilter={onFilter}
                    loading={loading}
                    selection={selectedCustomers}
                    onSelectionChange={onSelectionChange}
                    selectAll={selectAll}
                    onSelectAllChange={onSelectAllChange}
                  >
                    <Column
                      field="content"
                      header={t("anasayfa.tumHareketGecmisiTabloHareketİcerigi")}
                      filterPlaceholder={t("global.tablePlaceholderSearchText")}
                    />
                    <Column
                      field="createdDate"
                      header={t("anasayfa.tumHareketGecmisiTabloHareketTarihi")}
                      body={momentBodyTemplate}
                      filterPlaceholder={t("global.tablePlaceholderSearchText")}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(List, comparisonFn);

import {api} from '../constants/string';
import {HTTP} from "../axios/axios";

export function AuthoritiesGet() {
  return new Promise((res, rej) =>
      HTTP.get(`${api}/roles/authorities`)
          .then(response => {
              return res(response.data);
          })
          .catch((error) => {
               
              rej(error);
          }),
  );
}

export function AuthoritiesPost(data) {
    return new Promise((res, rej) =>
        HTTP.post(`${api}/roles/authorities`, data)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                 
                rej(error);
            }),
    );
  }

import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

// plugins
import { useTranslation } from "react-i18next";

/*Prime Component*/
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { LeadGet, LeadPut } from "../../service/lead";

/*Yetkilendirme ve Rol*/
import { leadDetay } from "../../constants/string";

import { JwtHelper } from "../../plugins/jwtHelper";

const Detail = () => {
  const { t } = useTranslation();

  const authorityList = JwtHelper.getAuthority(); //Yetkileri getirir
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [data, setData] = useState({
    name: "",
    code: "",
  });
  const location = useLocation();
  const [pageId] = useState(location.pathname.split("/")[2]);
  useEffect(() => {
    LeadGet(pageId).then((res) => {
      setIsLoading(false);

      setData(res);
    });
  }, [pageId]);

  const save = () => {
    setIsLoading(true);
    LeadPut(data)
      .then((res) => {
        setIsLoading(false);

        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Lead Güncellendi",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />
          {authorityList.includes(leadDetay) ? (
            <div className="col">
              <div className="card">
                <h5>{t("lead.leadDetayBaslik")}</h5>
                <p>{t("lead.leadDetayAciklama")}</p>
                <div className="grid">
                  <div className="col-12 md:col-12">
                    <div className="field">
                      <label htmlFor="teamName">{t("lead.leadInputAdi")}</label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="teamName"
                        value={data.name || ""}
                        onChange={(e) =>
                          setData({ ...data, name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-4"></div>
                  <div className="col-12 md:col-4"></div>
                  <div className="col-12 md:col-4">
                    <Button
                      label={t("global.btnUpdateText")}
                      onClick={save}
                      className="p-button-success mr-2 mb-2"
                      disabled={data.name ? false : true}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col">
              <div className="card">
                <h5>Lead</h5>
                <p>Seçtiğiniz Lead'ın Bilgilerini Aşağıda Görebilirsiniz.</p>
                <h2>Lütfen Yetkili Bir Kullanıcı İle Giriş Yapınız</h2>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Detail, comparisonFn);

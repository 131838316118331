/* eslint-disable array-callback-return */
import React, { useState, useRef } from "react";
// Prime Component

// plugins
import { useTranslation } from "react-i18next";

/*Component*/
import ContentLoading from "../loading/ContentLoading";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

/*Service*/
import {
  CustomersImportFirstTimePost,
  CustomerCreateAll,
} from "../../service/customer"; //Dökümanı yükle ve json olarak sonucu al

const CustomerAddBulkLeadPersonnel = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const [data, setData] = useState(null);

  const [selectedProducts7, setSelectedProducts7] = useState(null); //Checkbox İlle Seçilen Elemenları tutar

  const fileUpload = (e) => {
    setIsLoading(true);
    CustomersImportFirstTimePost(e.target.files[0])
      .then((res) => {
        setIsLoading(false);
        setData(res);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Döküman Eklendi",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  const customerPhoneBodyTemplate = (rowData) => {
    //Listeleme Telefon gösterimi
    return rowData.phoneNumberList.map((item, i) => {
      return (
        <span key={i}>
          {item.phoneNumber}
          <br></br>
        </span>
      );
    });
  };

  const save = () => {
    setIsLoading(true);
    selectedProducts7.map((item, i) => {
      item.leadState = { id: item.leadStateId };
      item.user = { id: item.userId };
    });

    let _data = data.filter((val) => !selectedProducts7.includes(val)); //Ham data ile seçilen dataları karşılaştırıp çıkarlalım.

    CustomerCreateAll(selectedProducts7)
      .then((res) => {
        //Seçilenleri kaydetmek için gönderelim
        setIsLoading(false);
        setData(_data); //Çıkarılan datalardan sonra ana datayı güncelleyelim
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Kayıtlar Eklendi",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />
          <div className="col-12">
            <div className="card">
              <h5>{t("topluMusteriEkleSorumluLead.Baslik")} </h5>
              <div className="col-12">
                <div className="grid">
                  <div className="col-12 md:col-4">
                    <input
                      type="file"
                      name="file"
                      className="form-control"
                      value=""
                      onChange={(e) => fileUpload(e)}
                      placeholder=""
                    />
                  </div>
                  <div className="col-12 md:col-8">
                    <div className="field" style={{ marginTop: 5 }}>
                      <Button
                        label={t("topluMusteriEkleSorumluLead.aktar")}
                        onClick={save}
                        className="p-button-success"
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  value={data}
                  selection={selectedProducts7}
                  onSelectionChange={(e) => setSelectedProducts7(e.value)}
                  dataKey="excelOrder"
                  responsiveLayout="scroll"
                  emptyMessage={t("global.tableNoData")}
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3em" }}
                  ></Column>
                  <Column
                    field="excelOrder"
                    header={t("topluMusteriEkleSorumluLead.sira")}
                  ></Column>
                  <Column
                    field="name"
                    header={t("topluMusteriEkleSorumluLead.adi")}
                  ></Column>
                  <Column
                    field="surname"
                    header={t("topluMusteriEkleSorumluLead.soyadi")}
                  ></Column>
                  <Column
                    body={customerPhoneBodyTemplate}
                    header={t("topluMusteriEkleSorumluLead.telefon")}
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CustomerAddBulkLeadPersonnel, comparisonFn);

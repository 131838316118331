import React, { useState, useRef } from "react";

/*Prime Component*/
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import { useTranslation } from "react-i18next";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { AuthoritiesPost } from "../../service/authorities";

const Add = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const [data, setData] = useState({
    name: "",
    code: "",
  });

  const save = () => {
    setIsLoading(true);
    AuthoritiesPost(data)
      .then((res) => {
        setIsLoading(false);
        setData({
          name: "",
          code: "",
        });

        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Yetki Eklendi",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />
          <div className="col">
            <div className="card">
              <h5>{t("yetki.yetkiBaslik")}</h5>
              <p>{t("yetki.yetkiAciklama")}</p>
              <div className="grid">
                <div className="col-12 md:col-12">
                  <div className="field">
                    <label htmlFor="teamName">{t("yetki.yetkiAdı")}</label>
                    <InputText
                      placeholder="Lütfen Takımın Adını Giriniz"
                      type="text"
                      id="teamName"
                      value={data.name || ""}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="teamName2">{t("yetki.yetkiKodu")}</label>
                    <InputText
                      placeholder="Lütfen Takımın Adını Giriniz"
                      type="text"
                      id="teamName2"
                      value={data.code || ""}
                      onChange={(e) =>
                        setData({ ...data, code: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12 md:col-4"></div>
                <div className="col-12 md:col-4"></div>
                <div className="col-12 md:col-4">
                  <Button
                    label="Kaydet"
                    onClick={save}
                    className="p-button-success mr-2 mb-2"
                    disabled={data.name && data.code ? false : true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Add, comparisonFn);

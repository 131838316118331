import { api } from "../constants/string";
import { HTTP } from "../axios/axios";

export function TicketPost(data) {
  return new Promise((res, rej) =>
    HTTP.post(`${api}/supports`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function TicketPut(data) {
  return new Promise((res, rej) =>
    HTTP.put(`${api}/supports`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function TicketGet(id) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/supports/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function TicketBySupport(id) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/support-chats/by-support/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}
export function TicketBySupportPost(data) {
  return new Promise((res, rej) =>
    HTTP.post(`${api}/support-chats`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function TicketGetList() {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/supports`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function TicketDetele(id) {
  return new Promise((res, rej) =>
    HTTP.delete(`${api}/supports/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

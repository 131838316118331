import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

// plugins
import { useTranslation } from "react-i18next";

/*Prime Component*/
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

// plugins
import moment from "moment";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { CustomersGet, CustomersPut } from "../../service/customer";
import { PersonnelGetList } from "../../service/personnel";
import { LeadGetList } from "../../service/lead";
import { NotesCustomerGet, NotesPost } from "../../service/note";
import {
  ReminderCustomerPost,
  ReminderCustomerGet,
} from "../../service/reminder";
import {
  Mt4TradesByLoginGetList,
  Mt4TradesByLoginCurrencyGetList,
} from "../../service/mt4Service";

/*Yetkilendirme ve Rol*/
import {
  musteriEMailAdresi,
  musteriAdi,
  musteriSoyadi,
  musteriSorumluPersonel,
  musteriGuncelle,
  musteriTelefonNumarasi,
  notGoruntuleme,
  notEkleme,
  momentDateFormat,
  meta4Listeleri,
} from "../../constants/string";
import { UlkeJson } from "../../constants/ulke";

import { JwtHelper } from "../../plugins/jwtHelper";

const Detail = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const location = useLocation();
  const [pageId] = useState(location.pathname.split("/")[2]);

  const [data, setData] = useState({
    name: "",
    surname: "",
    phoneNumberList: [],
    email: "",
    leadState: {},
    user: {},
    phoneId: null,
    phoneId2: null,
    customerId: null,
    customerId2: null,
    phone: "",
    phone2: "",
    source: "",
    source2: "",
    source3: "",
    metaId: "",
    metaAccountList: [],
    areaCode: "+90",
    areaCode2: "+90",
    selectedMetaId: "",
  });
  const [noteData, setNoteData] = useState({
    title: "",
    content: "",
    customer: {},
  });
  const [newNoteData, setNewNoteData] = useState(); //Yeni Not Eklenince Sayfa Yenilenmeden Gösterelim
  const [personnelList, setPersonnelList] = useState({});
  const [leadList, setleadListList] = useState({});
  const [customerNoteData, setcustomerNoteData] = useState([]);
  const [customerReminderList, setCustomerReminderList] = useState([]);
  const [metaIdList, setMetaIdList] = useState([{ login: "" }]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" },
      "country.name": { value: "", matchMode: "contains" },
      company: { value: "", matchMode: "contains" },
      "representative.name": { value: "", matchMode: "contains" },
    },
  });

  const authorityList = JwtHelper.getAuthority(); //Yetkileri getirir

  useEffect(() => {
    PersonnelGetList().then((res) => {
      //Personel Listesini Getirelim
      setPersonnelList(res);
      LeadGetList().then((res) => {
        //Lead Listesini Getirelim
        setleadListList(res);
        CustomersGet(pageId).then((res) => {
          if (res.metaAccountList.length > 0) {
            setMetaIdList(res.metaAccountList);
          }
          console.log("müşteri detay", res);
          //Müşteri detay Getirelim
          if (res.phoneNumberList.length > 0) {
            res.phone = res.phoneNumberList[0].phoneNumber;
            res.phoneId = res.phoneNumberList[0].id;
            res.customerId = res.phoneNumberList[0].customerId;
            res.areaCode = res.phoneNumberList[0].areaCode;
          }
          if (res.phoneNumberList.length > 1) {
            res.phone2 = res.phoneNumberList[1].phoneNumber;
            res.phoneId2 = res.phoneNumberList[1].id;
            res.customerId2 = res.phoneNumberList[1].customerId;
            res.areaCode2 = res.phoneNumberList[1].areaCode;
          }
          setData(res);
          NotesCustomerGet(pageId).then((res) => {
            //Müşteri Notlarını Getirelim
            setcustomerNoteData(res);
          });
          ReminderCustomerGet(pageId).then((res) => {
            //Müşteri Hatırlatıcılarını Getirelim
            setCustomerReminderList(res);
          });
          setIsLoading(false);
          // setTimeout(() => {
          //   mta4GetList(res.metaId);
          // }, 1000);
        });
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  const save = () => {
    function DefaultModelCreator(data) {
      return {
        id: data.id,
        phoneNumber: data.phoneNumber,
        customerId: data.customerId,
        areaCode: data.areaCode,
      };
    }

    setIsLoading(true);

    data.phoneNumberList = [];
    data.metaAccountList = metaIdList;
    if (
      !(data.phone === "" || data.phone === undefined || data.phone === null)
    ) {
      data.phoneNumberList.push(
        DefaultModelCreator({
          id: data.phoneId,
          customerId: data.customerId,
          phoneNumber: data.phone,
          areaCode: data.areaCode,
        })
      );
    }
    if (
      !(data.phone2 === "" || data.phone2 === undefined || data.phone2 === null)
    ) {
      data.phoneNumberList.push(
        DefaultModelCreator({
          id: data.phoneId2,
          customerId: data.customerId2,
          phoneNumber: data.phone2,
          areaCode: data.areaCode2,
        })
      );
    }
    CustomersPut(data)
      .then((res) => {
        setIsLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Müşteri Güncellendi",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  const notesSave = () => {
    noteData.customer.id = data.id;
    NotesPost(noteData)
      .then((res) => {
        setIsLoading(false);
        setNewNoteData(res);

        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Not Eklendi",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  const [reminderPostData, setReminderPostData] = useState({
    //Hatırlatıcı Model
    title: "",
    description: "",
    startDate: new Date(),
    customer: {
      id: null,
    },
  });
  const [customerReminderData, setCustomerReminderData] = useState(); //Yeni Eklenen hatırlatıcı
  const reminderSave = () => {
    reminderPostData.customer.id = data.id;
    ReminderCustomerPost(reminderPostData)
      .then((res) => {
        setIsLoading(false);
        setReminderPostData({
          title: "",
          description: "",
          startDate: new Date(),
          customer: {
            id: null,
          },
        });
        setCustomerReminderData(res);

        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Hatırlatıcı Eklendi",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  const [mta4GetListData, setMta4GetListData] = useState([]); //Mta4 Listesi
  const [mta4CurrencyGetData, setMta4CurrencyGetData] = useState({}); //Mta4 Para Birimi Listesi
  //Mta4 Get List
  //   const mta4GetList = (metaId) => {
  //     if (metaId !== null || metaId !== undefined || metaId !== "") {
  //       metaId = data.metaId;
  //     }
  //     Mt4TradesByLoginGetList(metaId, selectedPage, lazyParams.rows).then(
  //       (data) => {
  //         setTotalRecords(data.totalElements);
  //         setMta4GetListData(data);
  //         setLoading(false);
  //       }
  //     );
  //   };

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      Mt4TradesByLoginGetList(
        data.selectedMetaId,
        selectedPage,
        lazyParams.rows
      ).then((data) => {
        setTotalRecords(data.totalElements);
        setMta4GetListData(data);
        setLoading(false);
      });
    }, Math.random() * 1000 + 250);
  };

  const onPage = (event) => {
    setLazyParams(event);
    lazyParams.rows = event.rows;
    setSelectedPage(event.page);
  };

  const onSort = (event) => {
    setLazyParams(event);
  };
  const openTimeBodyTemplate = (rowData) => {
    return moment(rowData.openTime).format(momentDateFormat);
  };
  // const modifyTimeBodyTemplate = (rowData) => {
  //   return moment(rowData.modifyTime).format(momentDateFormat);
  // };
  // const expirationBodyTemplate = (rowData) => {
  //   return moment(rowData.expiration).format(momentDateFormat);
  // };
  const closeTimeBodyTemplate = (rowData) => {
    return moment(rowData.closeTime).format(momentDateFormat);
  };

  // handle input change
  const handleMetaIdInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...metaIdList];
    list[index][name] = value;
    setMetaIdList(list);
  };

  // handle click event of the Remove button
  const handleMetaIdRemoveClick = (index) => {
    const list = [...metaIdList];
    list.splice(index, 1);
    setMetaIdList(list);
  };

  // handle click event of the Add button
  const handleMetaIdAddClick = () => {
    setMetaIdList([...metaIdList, { login: "" }]);
  };
  const UlkeOptionTemplate = (option) => {
    return (
      <div>
        ({option.dial_code}) {option.name}
      </div>
    );
  };

  const selectMta4 = (data) => {
    Mt4TradesByLoginGetList(data.value, selectedPage, lazyParams.rows).then(
      (data) => {
        setTotalRecords(data.totalElements);
        setMta4GetListData(data);
        setLoading(false);
      }
    );
    Mt4TradesByLoginCurrencyGetList(data.value).then((data) => {
      setMta4CurrencyGetData(data);
    });
  };

  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="p-fluid">
          <div className="card">
            <TabView>
              <TabPanel header="Müşteri Detay">
                <h5>
                  {data.name} {data.surname} kişisinin detayı
                </h5>
                <div className="grid">
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="teamName">{t("musteri.adi")}</label>

                      {authorityList.includes(musteriAdi) ? (
                        <InputText
                          placeholder={t("global.placeholderInput")}
                          type="text"
                          id="teamName"
                          value={data.name || ""}
                          onChange={(e) =>
                            setData({ ...data, name: e.target.value })
                          }
                        />
                      ) : (
                        <span className="d-block w-100 bold">{data.name}</span>
                      )}
                    </div>
                    {authorityList.includes(musteriEMailAdresi) && (
                      <div className="field">
                        <label htmlFor="customerEmail">
                          {t("musteri.email")}
                        </label>
                        <InputText
                          placeholder={t("global.placeholderInput")}
                          type="text"
                          id="customerEmail"
                          value={data.email || ""}
                          onChange={(e) =>
                            setData({ ...data, email: e.target.value })
                          }
                        />
                      </div>
                    )}

                    {authorityList.includes(musteriSorumluPersonel) && (
                      <div className="field">
                        <label htmlFor="personnelEMail">
                          {t("musteri.sorumlu")}
                        </label>
                        <Dropdown
                          value={data.user}
                          options={personnelList}
                          onChange={(e) => setData({ ...data, user: e.value })}
                          optionLabel="name"
                          filter
                          showClear
                          filterBy="name"
                          placeholder={t("global.placeholderSelect")}
                        />
                      </div>
                    )}
                    <div className="field">
                      <label htmlFor="personnelEMail">
                        {t("musteri.lead")}
                      </label>
                      <Dropdown
                        value={data.leadState}
                        options={leadList}
                        onChange={(e) =>
                          setData({ ...data, leadState: e.value })
                        }
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        placeholder={t("global.placeholderSelect")}
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="customerEmail">Müşteri Id</label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="customerEmail"
                        value={data.id || ""}
                        disabled
                      />
                    </div>

                    {metaIdList.map((x, i) => {
                      return (
                        <div className="box">
                          <div className="field">
                            <label htmlFor="name" className="d-block">
                              {t("musteri.metaId")}
                            </label>
                            <InputText
                              style={{ width: "80%" }}
                              placeholder={t("global.placeholderInput")}
                              type="text"
                              name="login"
                              value={x.login}
                              onChange={(e) => handleMetaIdInputChange(e, i)}
                            />
                            {metaIdList.length - 1 === i && (
                              <Button
                                style={{ top: 7 }}
                                icon="pi pi-plus"
                                className="mr-2 mb-2 p-button-success"
                                onClick={handleMetaIdAddClick}
                              ></Button>
                            )}
                            {metaIdList.length !== 1 && (
                              <Button
                                style={{ top: 7 }}
                                icon="pi pi-trash"
                                className="mr-2 mb-2 p-button-danger"
                                onClick={() => handleMetaIdRemoveClick(i)}
                              ></Button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="teamCode">{t("musteri.soyadi")}</label>
                      {authorityList.includes(musteriSoyadi) ? (
                        <InputText
                          placeholder={t("global.placeholderInput")}
                          type="text"
                          id="teamCode"
                          value={data.surname || ""}
                          onChange={(e) =>
                            setData({ ...data, surname: e.target.value })
                          }
                        />
                      ) : (
                        <span className="d-block w-100 bold">
                          {data.surname}
                        </span>
                      )}
                    </div>
                    {authorityList.includes(musteriTelefonNumarasi) && (
                      <>
                        <div className="field">
                          <label htmlFor="personnelEMail11">
                            {t("musteri.telefonKodu")}
                          </label>
                          <Dropdown
                            value={data.areaCode}
                            options={UlkeJson}
                            onChange={(e) =>
                              setData({ ...data, areaCode: e.value })
                            }
                            optionLabel="name"
                            optionValue="dial_code"
                            itemTemplate={UlkeOptionTemplate}
                            filter
                            showClear
                            filterBy="name"
                            placeholder={t("global.placeholderSelect")}
                          />
                        </div>
                        <div className="field">
                          <label htmlFor="customerPhone">
                            {t("musteri.telefon")}
                          </label>
                          <InputText
                            placeholder={t("global.placeholderInput")}
                            type="text"
                            id="customerPhone"
                            value={data.phone || ""}
                            onChange={(e) =>
                              setData({
                                ...data,
                                phone: e.target.value.replace(/[^0-9]/g, ""),
                              })
                            }
                          />
                        </div>
                        <div className="field">
                          <label htmlFor="personnelEMail11">
                            {t("musteri.telefonKodu")}
                          </label>
                          <Dropdown
                            value={data.areaCode2}
                            options={UlkeJson}
                            onChange={(e) =>
                              setData({ ...data, areaCode2: e.value })
                            }
                            optionLabel="name"
                            optionValue="dial_code"
                            itemTemplate={UlkeOptionTemplate}
                            filter
                            showClear
                            filterBy="name"
                            placeholder={t("global.placeholderSelect")}
                          />
                        </div>
                        <div className="field">
                          <label htmlFor="customerPhone2">
                            {t("musteri.telefon2")}
                          </label>
                          <InputText
                            placeholder={t("global.placeholderInput")}
                            type="text"
                            id="customerPhone2"
                            value={data.phone2 || ""}
                            onChange={(e) =>
                              setData({
                                ...data,
                                phone2: e.target.value.replace(/[^0-9]/g, ""),
                              })
                            }
                          />
                        </div>
                      </>
                    )}
                    <div className="field">
                      <label htmlFor="teamSource">{t("musteri.kaynak")}</label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="teamSource"
                        value={data.source}
                        disabled
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="teamSource">
                        {t("musteri.kaynak")} 2
                      </label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="teamSource"
                        value={data.source2 || ""}
                        onChange={(e) =>
                          setData({ ...data, source2: e.target.value })
                        }
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="customerSource3">
                        {t("musteri.kaynak")} 3
                      </label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="customerSource3"
                        value={data.source3 || ""}
                        onChange={(e) =>
                          setData({ ...data, source3: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-4"></div>
                  <div className="col-12 md:col-4"></div>
                  <div className="col-12 md:col-4">
                    {authorityList.includes(musteriGuncelle) && (
                      <Button
                        label={t("global.btnUpdateText")}
                        onClick={save}
                        className="p-button-success mr-2 mb-2"
                        disabled={data.phone ? false : true}
                      />
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Müşteri Notu">
                {authorityList.includes(notGoruntuleme) && (
                  <div className="grid">
                    <h5 className="d-block w-100">
                      {data.name} {data.surname} kişisinin notları
                    </h5>
                    {newNoteData && (
                      <div className="col-12 md:col-4">
                        <Card
                          title={newNoteData.title}
                          subTitle={"Oluşturan:" + newNoteData.createdUser}
                          style={{ width: "100%" }}
                        >
                          <p className="m-0" style={{ lineHeight: "1.5" }}>
                            {newNoteData.content}
                          </p>
                          <div className="p-card-subtitle mt-4">
                            Oluşturma Tarihi;<br></br>
                            <b>
                              {moment(newNoteData.createdDate).format(
                                momentDateFormat
                              )}
                            </b>
                          </div>
                        </Card>
                      </div>
                    )}
                    {customerNoteData.map((item, i) => {
                      return (
                        <div className="col-12 md:col-4" key={i}>
                          <Card
                            title={item.title}
                            subTitle={"Oluşturan:" + item.createdUser}
                            style={{ width: "100%" }}
                          >
                            <p className="m-0" style={{ lineHeight: "1.5" }}>
                              {item.content}
                            </p>
                            <div className="p-card-subtitle mt-4">
                              Oluşturma Tarihi;<br></br>
                              <b>
                                {moment(item.createdDate).format(
                                  momentDateFormat
                                )}
                              </b>
                            </div>
                          </Card>
                        </div>
                      );
                    })}
                  </div>
                )}
                {authorityList.includes(notEkleme) && (
                  <div>
                    <div className="field">
                      <label htmlFor="teamCode">{t("musteri.not")}</label>
                      <InputText
                        placeholder={t("global.placeholderInput")}
                        type="text"
                        id="customerNoteTitle"
                        value={noteData.title || ""}
                        onChange={(e) =>
                          setNoteData({ ...noteData, title: e.target.value })
                        }
                      />
                      <InputTextarea
                        className="mt-2"
                        placeholder={t("global.placeholderInput")}
                        value={noteData.content}
                        onChange={(e) =>
                          setNoteData({ ...noteData, content: e.target.value })
                        }
                        rows={5}
                        cols={30}
                      />
                    </div>
                    <div className="field">
                      <Button
                        label="Müşteri Notu Ekle"
                        onClick={notesSave}
                        className="p-button-success mr-2 mb-2"
                        disabled={noteData.content ? false : true}
                      />
                    </div>
                  </div>
                )}
              </TabPanel>
              <TabPanel header="Müşteri Hatırlatıcı">
                <div>
                  <h5>
                    {data.name} {data.surname} kişisinin hatırlatıcısı
                  </h5>
                  {customerReminderData && (
                    <div className="col-12 md:col-4">
                      <Card
                        title={customerReminderData.title}
                        subTitle={
                          "Oluşturan:" + customerReminderData.createdUser
                        }
                        style={{ width: "100%" }}
                      >
                        <p className="m-0" style={{ lineHeight: "1.5" }}>
                          {customerReminderData.description}
                        </p>
                        <div className="p-card-subtitle mt-4">
                          Hatırlatıcı Tarihi;<br></br>
                          <b>
                            {moment(customerReminderData.startDate).format(
                              momentDateFormat
                            )}
                          </b>
                        </div>
                      </Card>
                    </div>
                  )}
                  {customerReminderList.map((item, i) => {
                    return (
                      <div className="col-12 md:col-4" key={i}>
                        <Card
                          title={item.title}
                          subTitle={"Oluşturan:" + item.createdUser}
                          style={{ width: "100%" }}
                        >
                          <p className="m-0" style={{ lineHeight: "1.5" }}>
                            {item.description}
                          </p>
                          <div className="p-card-subtitle mt-4">
                            Hatırlatıcı Tarihi;<br></br>
                            <b>
                              {moment(item.startDate).format(momentDateFormat)}
                            </b>
                          </div>
                        </Card>
                      </div>
                    );
                  })}
                </div>
                <div className="field">
                  <label htmlFor="teamName">Hatırlatıcı Adı</label>
                  <InputText
                    placeholder={t("global.placeholderInput")}
                    type="text"
                    id="teamName"
                    value={reminderPostData.title || ""}
                    onChange={(e) =>
                      setReminderPostData({
                        ...reminderPostData,
                        title: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="field">
                  <label htmlFor="reminderDescription">
                    Hatırlatıcı Açıklama
                  </label>
                  <InputText
                    placeholder={t("global.placeholderInput")}
                    type="text"
                    id="reminderDescription"
                    value={reminderPostData.description || ""}
                    onChange={(e) =>
                      setReminderPostData({
                        ...reminderPostData,
                        description: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="field">
                  <label htmlFor="teamName">Hatırlatıcı Tarihi</label>
                  <Calendar
                    id="time24"
                    value={reminderPostData.startDate}
                    onChange={(e) =>
                      setReminderPostData({
                        ...reminderPostData,
                        startDate: e.value,
                      })
                    }
                    showTime
                    showIcon
                    showSeconds
                    placeholder={t("global.placeholderSelect")}
                  />
                </div>
                <div className="field">
                  <Button
                    label="Hatırlatıcı Ekle"
                    onClick={reminderSave}
                    className="p-button-success mr-2 mb-2"
                    disabled={reminderPostData.title ? false : true}
                  />
                </div>
              </TabPanel>

              {authorityList.includes(meta4Listeleri) && (
                <TabPanel header="Müşteri MT4">
                  <div className="field">
                    <label htmlFor="personnelEMail">Müşteri MT4 ID</label>
                    <Dropdown
                      value={data.selectedMetaId}
                      options={metaIdList}
                      onChange={(e) => [
                        selectMta4(e),
                        setData({ ...data, selectedMetaId: e.value }),
                      ]}
                      optionLabel="login"
                      optionValue="login"
                      filter
                      showClear
                      filterBy="login"
                      placeholder={t("global.placeholderSelect")}
                    />
                  </div>
                  <h5>
                    {data.name} {data.surname} kişisinin MT4 trade
                  </h5>
                  {data.selectedMetaId && (
                    <>
                      <div className="inc-exp-container">
                        <div>
                          <h4>Toplam Para Girişi</h4>
                          <p id="money-plus" className="money plus">
                            ${mta4CurrencyGetData.entryTotal}
                          </p>
                        </div>
                        <div>
                          <h4>Bakiye</h4>
                          <p id="money-plus" className="money plus">
                            ${mta4CurrencyGetData.total}
                          </p>
                        </div>
                        <div>
                          <h4>Toplam Para Çıkışı</h4>
                          <p id="money-minus" className="money minus">
                            ${mta4CurrencyGetData.outTotal}
                          </p>
                        </div>
                      </div>
                      <DataTable
                        value={mta4GetListData.content}
                        lazy
                        responsiveLayout="scroll"
                        dataKey="id"
                        paginator
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                        totalRecords={totalRecords}
                        onPage={onPage}
                        onSort={onSort}
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}
                        loading={loading}
                      >
                        <Column field="volume" header="volume"></Column>
                        <Column field="tp" header="tp"></Column>
                        {/* <Column field="timestamp" header="timestamp"></Column> */}
                        <Column field="ticket" header="ticket"></Column>
                        {/* <Column field="taxes" header="taxes"></Column> */}
                        <Column field="symbol" header="symbol"></Column>
                        <Column field="swaps" header="swaps"></Column>
                        <Column field="sl" header="sl"></Column>
                        {/* <Column field="reason" header="reason"></Column> */}
                        <Column field="profit" header="profit"></Column>
                        <Column header="openTime" body={openTimeBodyTemplate} />
                        <Column field="openPrice" header="openPrice"></Column>
                        {/* <Column header="modifyTime" body={modifyTimeBodyTemplate} /> */}
                        {/* <Column field="marginRate" header="marginRate"></Column> */}
                        {/* <Column field="magic" header="magic"></Column> */}
                        <Column field="login" header="login"></Column>
                        {/* <Column field="internalId" header="internalId"></Column> */}
                        {/* <Column field="gwVolume" header="gwVolume"></Column> */}
                        {/* <Column field="gwOpenPrice" header="gwOpenPrice"></Column> */}
                        {/* <Column field="gwClosePrice" header="gwClosePrice"></Column> */}

                        {/* <Column header="expiration" body={expirationBodyTemplate} /> */}
                        {/* <Column field="digits" header="digits"></Column> */}
                        {/* <Column field="convRate2" header="convRate2"></Column> */}
                        {/* <Column field="convRate1" header="convRate1"></Column> */}
                        {/* <Column field="commissionAgent" header="commissionAgent"></Column> */}
                        <Column field="commission" header="commission"></Column>
                        <Column field="comment" header="comment"></Column>
                        {/* <Column field="cmd" header="cmd"></Column> */}
                        <Column
                          header="closeTime"
                          body={closeTimeBodyTemplate}
                        />
                        <Column field="closePrice" header="closePrice"></Column>
                      </DataTable>
                      <span
                        className="mr-3 mt-3"
                        style={{ display: "inline-block" }}
                      >
                        Toplam Kayıt Sayısı: {mta4GetListData.totalElements}
                      </span>
                      <span
                        className="mr-3 mt-3"
                        style={{ display: "inline-block" }}
                      >
                        Toplam Sayfa Sayısı: {mta4GetListData.totalPages}
                      </span>
                    </>
                  )}
                </TabPanel>
              )}
            </TabView>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Detail, comparisonFn);

import {api} from '../constants/string';
import {HTTP} from "../axios/axios";

export function LoginUser(username, password) {

    return new Promise((res, rej) =>
        HTTP.post(`${api}/auth/login`, {username, password})
            .then(response => {
                //setMessage('success', 'Successfully logged in');
                localStorage.setItem("token", response.data)
                return res(response.data);
            })
            .catch((error) => {
                //setMessage('error', message);
                rej(error);
            }),
    );
}
export function TeamUserGetList(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/users/group/${id}`)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}
import React, {useEffect, useRef, useState} from 'react';
import Login from './components/auth/Login';
import Main from './pages/Main';

/*Context*/
import {MainContext} from './context/Context';
import {ReminderUserGet, ReminderUserSetReadingTrue} from "./service/reminder";
import {JwtHelper} from "./plugins/jwtHelper";
import {Toast} from "primereact/toast";
import {confirmDialog, ConfirmDialog} from "primereact/confirmdialog";

import './App.scss';
const App = () => {

    const [data, setData] = useState({})
    const toast = useRef(null);

    useEffect(() => {
        if (!(localStorage.token === 'Undefined' || localStorage.token === "" || localStorage.token === undefined)) {
            setIsPersonnel(true)
        }

        //Bildirim kontrolünü belirli aralıklarla kontrol ediyoruz.
        //Eğer okunmamış bildirim var ise ekranda gösteriyoruz.
        setInterval(() => {
            if (JwtHelper.getUserId() !== null || JwtHelper.getUserId() !== undefined) {
                ReminderUserGet(JwtHelper.getUserId()).then(res => {
                    setData({notifications: res});
                    for (let item of res) {
                        if (!item.reading) {
                            confirmDialog({
                                message: item.content,
                                header: "Bildirim",
                                acceptLabel: "Tamam",
                                rejectLabel: "Yoksay",
                                accept: async () => {
                                    await ReminderUserSetReadingTrue(item.id, true);
                                }
                            })
                        }
                    }
                })
            }
        }, 10000);

    }, []);

    const [isPersonnel, setIsPersonnel] = useState(false)


    if (isPersonnel) {
        return (
            <MainContext.Provider value={data}>
                <ConfirmDialog/>
                <Toast ref={toast}/>
                <Main/>
            </MainContext.Provider>
        )
    }

    return (
        <MainContext.Provider value={data}>
            <Login/>
        </MainContext.Provider>
    )
};
export default App;
